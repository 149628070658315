import configs from "./configs";

var isChatLoading = false;

export function chatlogPost(apiPath, apiParams, apiCallback = null){
  if (isChatLoading == true){
    return false;
  }
  let xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open('POST', configs.url.liveXRApiBase + '/chat/' + apiPath);
  xhr.responseType = 'json';
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onload = function () {
    if (apiCallback != null) {
      apiCallback(xhr.response);
    }
  }
  xhr.send(JSON.stringify(apiParams));
}