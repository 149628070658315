import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./RaiseHand.scss";
import { RaiseHandToolbarButton } from "./RaiseHandComponent";
import merge from "deepmerge";

export function RaiseHandToolbarButtonContainer(props) {
  return <RaiseHandToolbarButton {...props} />
}
