import React, { useRef, useState, useEffect } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { ToolbarButton } from "../input/ToolbarButton";
import { ReactComponent as ControlIcon } from "../icons/Button_Ctrl.svg";
import { ReactComponent as CrossIcon } from "../icons/Cross.svg";
import styleInstructionModal from "./ControlInstructionModal.scss";

import buttonMoveInstructionImageOff from "../../assets/images/controlinstruction/SP/Detail/Button_menu_move.png"
import buttonMoveInstructionImageOn from "../../assets/images/controlinstruction/SP/Detail/Button_menu_move_Selected.png"
import buttonRotateInstructionImageOff from "../../assets/images/controlinstruction/SP/Detail/Button_menu_rotate.png"
import buttonRotateInstructionImageOn from "../../assets/images/controlinstruction/SP/Detail/Button_menu_rotate_Selected.png"

import pcButtonOn from "../../assets/images/controlinstruction/PC/Toggle_PC_ON.png"
import pcButtonOff from "../../assets/images/controlinstruction/PC/Toggle_PC_OFF.png"
import spButtonOn from "../../assets/images/controlinstruction/SP/Toggle_SP_ON.png"
import spButtonOff from "../../assets/images/controlinstruction/SP/Toggle_SP_OFF.png"

import { useCssBreakpoints } from "react-use-css-breakpoints";

const LOCAL_STORE_KEY = "___livexr_store_skipcontrolinstrustion";

export function ControlInstructionModal({
  onClose,
  ...rest
}) {
  const breakpoint = useCssBreakpoints();
  
  const isMobile = AFRAME.utils.device.isMobile();

  const onToggleCheckBox = (event) => {
    var checkbox = document.querySelector("#shownOnNextTimeCheckbox");

    if (checkbox.checked) {
      localStorage.setItem(LOCAL_STORE_KEY, "true");
    } else {
      localStorage.removeItem(LOCAL_STORE_KEY);
    }
  }
  return ( 
    <div className={styleInstructionModal.instructionModalContainer}>
      <div className={styleInstructionModal.instructionModalRelativeContainer}>
        <div className={styleInstructionModal.instructionModalCloseBtnContainer}>
          <div className={styleInstructionModal.instructionModalCloseBtn}>
            <CrossIcon onClick={onClose} />
          </div>
        </div>
        <div className={isMobile? styleInstructionModal.instructionModalContentContainerSP: styleInstructionModal.instructionModalContentContainer}>
        </div>
        <div className={styleInstructionModal.instructionModalContentSkipNext}>
          <input id="shownOnNextTimeCheckbox" name="shownOnNextTimeCheckbox" type="checkbox" onChange={onToggleCheckBox}></input><label for="shownOnNextTimeCheckbox"><FormattedMessage id="control-instruction.skip" defaultMessage="Do not show this message next time" /></label>
        </div>
      </div>
    </div>
  )
}
ControlInstructionModal.propTypes = {
  onClose: PropTypes.func
};

export function ControlInstructionViewport({ onClose, children, isEntered, ...rest }) {
  const isMobile = AFRAME.utils.device.isMobile();

  return (
    <div
      className={isMobile? styleInstructionModal.instructionPopupContainerSP: styleInstructionModal.instructionPopupContainer}
      onClose={onClose}
      {...rest}
    >
      <div className={isMobile? styleInstructionModal.instructionPopupDetailContainerSP: (isEntered) ? styleInstructionModal.instructionPopupDetailContainer: styleInstructionModal.instructionPopupDetailContainer2}>
      </div>
    </div>
  );
}

export function ControlInstructionDetailViewport({
  onClose,
  ...rest
}) {
  const [isMoveInstruction, setIsMoveInstruction] = useState(true)

  const isMobile = AFRAME.utils.device.isMobile();

  return isMobile? ( 
    <div className={styleInstructionModal.instructionModalDetailModalContainerSP}>
      <div className={styleInstructionModal.instructionModalRelativeContainer}>
        <div className={styleInstructionModal.instructionModalCloseBtnContainer}>
          <div className={styleInstructionModal.instructionModalCloseBtn}>
            <CrossIcon onClick={onClose} />
          </div>
        </div>

        <div className={styleInstructionModal.instructionModalDetailTitleContainer}><FormattedMessage id="control-instruction.title" defaultMessage="Control Instruction" /></div>

        <div className={styleInstructionModal.instructionModalDetailSelectContentContainer}>
          <div>
            <input type="image" onClick={() => {setIsMoveInstruction(true)}} src={isMoveInstruction? buttonMoveInstructionImageOn: buttonMoveInstructionImageOff} />
          </div>
          <div>
            <input type="image" onClick={() => {setIsMoveInstruction(false)}} src={isMoveInstruction? buttonRotateInstructionImageOff: buttonRotateInstructionImageOn} />
          </div>
        </div>

        <div className={isMoveInstruction? styleInstructionModal.instructionModalDetailContentContainer: styleInstructionModal.instructionModalDetailContentContainer2}>
        </div>
      </div>
    </div>
  ):( 
    <div className={styleInstructionModal.instructionModalDetailModalContainerPC}>
      <div className={styleInstructionModal.instructionModalRelativeContainer}>
        <div className={styleInstructionModal.instructionModalCloseBtnContainer}>
          <div className={styleInstructionModal.instructionModalCloseBtn}>
            <CrossIcon onClick={onClose} />
          </div>
        </div>
        <div className={styleInstructionModal.instructionModalDetailTitleContainer}><FormattedMessage id="control-instruction.title" defaultMessage="Control Instruction" /></div>
        <div className={styleInstructionModal.instructionModalDetailContentContainer}>
        </div>
      </div>
    </div>
  )
}

export function ControlInstructionViewportButton(props) {
  const {label, isOn, onClick} = props;

  const isMobile = AFRAME.utils.device.isMobile();
  const buttonImage = isMobile? (
    (isOn)? spButtonOn: spButtonOff
  ): (
    (isOn)? pcButtonOn: pcButtonOff
  );

  return (
    <div
      className={isMobile? styleInstructionModal.instructionViewportBtnSP: styleInstructionModal.instructionViewportBtn}
    >
      <input type="image" onClick={onClick} src={buttonImage} />
    </div>
    
    
  );
}

export function ControlInstructionToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={<ControlIcon />}
      preset="accent5"
      label={<FormattedMessage id="control-instruction.toolbar" defaultMessage="Control" />}
    />
  );
}

