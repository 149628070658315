import { isLocalHubsUrl, isLocalHubsSceneUrl, isHubsRoomUrl, isLocalHubsAvatarUrl } from "../utils/media-url-utils";
import { guessContentType } from "../utils/media-url-utils";
import { handleExitTo2DInterstitial } from "../utils/vr-interstitial";
import { getObjectNameGA, processShowIframe, processShowVideoEmbed, processShowArchiveVideo } from "../utils/livexr-utils";
import registerEvent from "../telemetry-event";
import { changeHub } from "../change-hub";
import configs from "../utils/configs";

AFRAME.registerComponent("open-media-button", {
  schema: {
    onlyOpenLink: { type: "boolean" }
  },
  findLinkType(objectName) {
    //Type->
    //0: Default
    //1: Link URL
    //2: Download
    //3: SNS
    //4: Transition
    //5: Video
    //6: Image
    //7: 3DObject
    if (objectName.indexOf('TLink') !== -1) {
      return 1;
    }

    if (objectName.indexOf('TDownload') !== -1) {
      return 2;
    }

    if (objectName.indexOf('TSNS') !== -1) {
      return 3;
    }

    if (objectName.indexOf('TTransition') !== -1) {
      return 4;
    }

    if (objectName.indexOf('TVideo') !== -1) {
      return 5;
    }

    if (objectName.indexOf('TImage') !== -1) {
      return 6;
    }

    if (objectName.indexOf('T3DObject') !== -1) {
      return 7;
    }

    if (objectName.indexOf('TTransparent') !== -1) {
      return 8;
    }

    return 0;
  },
  init() {
    const objOriginName = this.el.object3D.parent.el.object3D.parent.el.object3D.name;
    this.allowEmptyUrl = 
      objOriginName.includes("2D_iFrame") || 
      objOriginName.includes("2D_ArchiveVideo") || 
      objOriginName.includes("2D_VideoEmbed")? true: false;    
    this.linkType = this.findLinkType(objOriginName);
    this.generateThumbnail = (this.linkType !== 0)? false: true;
    this.label = this.el.querySelector("[text]");

    this.updateSrc = async () => {
      if (!this.targetEl.parentNode) return; // If removed
      const mediaLoader = this.targetEl.components["media-loader"].data;
      const src = (this.src = this.allowEmptyUrl? configs.url.landingPage: (mediaLoader.mediaOptions && mediaLoader.mediaOptions.href) || mediaLoader.src);
      const visible = src && guessContentType(src) !== "video/vnd.hubs-webrtc";
      const mayChangeScene = this.el.sceneEl.systems.permissions.canOrWillIfCreator("update_hub");

      this.el.object3D.visible = !!visible;

      if (visible) {
        let label = "open link";
        if (!this.data.onlyOpenLink) {
          let hubId;
          if (!this.generateThumbnail) {
            if ((hubId = await isHubsRoomUrl(src))) {
              const url = new URL(src);
              if (url.hash && window.APP.hub.hub_id === hubId) {
                label = "go to";
              } else {
                label = "visit room";
              }
            }
          } else if (this.allowEmptyUrl) {
            if (objOriginName.includes("2D_iFrame")) {
              label = "open";
            } else if(objOriginName.includes("2D_ArchiveVideo")) {
              label = "play";
            } else if(objOriginName.includes("2D_VideoEmbed")) {
              label = "play";
            }
          } else if (await isLocalHubsAvatarUrl(src)) {
            label = "use avatar";
          } else if ((await isLocalHubsSceneUrl(src)) && mayChangeScene) {
            label = "use scene";
          } else if ((hubId = await isHubsRoomUrl(src))) {
            const url = new URL(src);
            if (url.hash && window.APP.hub.hub_id === hubId) {
              label = "go to";
            } else {
              label = "visit room";
            }
          }
        }
        this.label.setAttribute("text", "value", label);
      }
    };

    this.onClick = async () => {
      setTimeout(async () => {
        const mayChangeScene = this.el.sceneEl.systems.permissions.canOrWillIfCreator("update_hub");

        const exitImmersive = async () => await handleExitTo2DInterstitial(false, () => {}, true);

        let hubId;
        if (this.data.onlyOpenLink) {
          const objOriginName = this.el.object3D.parent.el.object3D.parent.el.object3D.name;
          if (objOriginName.includes("iFrame")) {
            processShowIframe(objOriginName);
          } else if (objOriginName.includes("ArchiveVideo")) {
            processShowArchiveVideo(objOriginName);
          } else if (objOriginName.includes("VideoEmbed")) {
            processShowVideoEmbed(objOriginName);
          } else {
            const objectName = getObjectNameGA(objOriginName);
            const GAName = window.APP.hub.name + "_" + objectName;
            registerEvent("External_Link", GAName, this.src);
            await exitImmersive();
            window.open(this.src);
          }
        } else if (this.generateThumbnail && !this.allowEmptyUrl && await isLocalHubsAvatarUrl(this.src)) {
          const avatarId = new URL(this.src).pathname.split("/").pop();
          window.APP.store.update({ profile: { avatarId } });
          this.el.sceneEl.emit("avatar_updated");
        } else if (this.generateThumbnail && !this.allowEmptyUrl && (await isLocalHubsSceneUrl(this.src)) && mayChangeScene) {
          this.el.sceneEl.emit("scene_media_selected", this.src);
        } else if (this.generateThumbnail && !this.allowEmptyUrl && (hubId = await isHubsRoomUrl(this.src))) {
          const url = new URL(this.src);
          if (url.hash && window.APP.hub.hub_id === hubId) {
            // move to waypoint w/o writing to history
            window.history.replaceState(null, null, window.location.href.split("#")[0] + url.hash);
          } else if (APP.store.state.preferences.fastRoomSwitching && isLocalHubsUrl(this.src)) {
            // move to new room without page load or entry flow
            changeHub(hubId);
          } else {
            registerEvent("Room_Link", this.el.object3D.parent.el.object3D.parent.el.object3D.name, this.src);
            await exitImmersive();
            const qstring = new URLSearchParams(location.search);
            var extendString = "";
            if (qstring.has("pid")) {
              extendString += (extendString == ""? "?": "") + "pid=" + qstring.get("pid");
            }
            if (qstring.has("eid")) {
              extendString += (extendString == ""? "?": "&") + "eid=" + qstring.get("eid");
            }
            console.log("extendString: ", extendString);
            console.log("this.src + extendString: ", this.src + extendString);
            location.href = this.src + extendString;
          }
        } else {
          const objOriginName = this.el.object3D.parent.el.object3D.parent.el.object3D.name;
          const objectName = getObjectNameGA(objOriginName);
          const GAName = window.APP.hub.name + "_" + objectName;
          if (objOriginName.includes("iFrame")) {
            const returnData = processShowIframe(objOriginName);
            if (returnData) {
              registerEvent("iFrame_Modal", GAName, returnData.src);
            }
          } else if (objOriginName.includes("ArchiveVideo")) {
            const returnData = processShowArchiveVideo(objOriginName);
            if (returnData) {
              registerEvent("Archive_Video", GAName, returnData.src);
            }
          } else if (objOriginName.includes("VideoEmbed")) {
            const returnData = processShowVideoEmbed(objOriginName);
          } else if (hubId = await isHubsRoomUrl(this.src)) {
            console.log("All Hubs_Link", this.src);
            const url = new URL(this.src);
            if (url.hash && window.APP.hub.hub_id === hubId) {
              // move to waypoint w/o writing to history
              window.history.replaceState(null, null, window.location.href.split("#")[0] + url.hash);
            } else if (APP.store.state.preferences.fastRoomSwitching && isLocalHubsUrl(this.src)) {
              // move to new room without page load or entry flow
              changeHub(hubId);
            } else {
              registerEvent("Room_Link", this.el.object3D.parent.el.object3D.parent.el.object3D.name, this.src);
              await exitImmersive();

              const qstring = new URLSearchParams(location.search);
              var extendString = "";
              if (qstring.has("pid")) {
                extendString += (extendString == ""? "?": "") + "pid=" + qstring.get("pid");
              }
              if (qstring.has("eid")) {
                extendString += (extendString == ""? "?": "&") + "eid=" + qstring.get("eid");
              }
              console.log("extendString: ", extendString);
              console.log("this.src + extendString: ", this.src + extendString);
              location.href = this.src + extendString;
              //location.href = this.src;
            }
          } else {
            registerEvent("External_Link", GAName, this.src);
            await exitImmersive();
            window.open(this.src);
          }
        }
      }, 100);
    };

    NAF.utils.getNetworkedEntity(this.el).then(networkedEl => {
      this.targetEl = networkedEl;
      this.targetEl.addEventListener("media_resolved", this.updateSrc, { once: true });
      this.updateSrc();
    });
  },

  play() {
    this.el.object3D.addEventListener("interact", this.onClick);
  },

  pause() {
    this.el.object3D.removeEventListener("interact", this.onClick);
  }
});
