import React, { forwardRef, memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InputField } from "./InputField";
import { TextInput } from "./TextInput";
import { useId } from "./useId";
import { Button } from "./Button";
import { FormattedMessage } from "react-intl";

import { Auth, API } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import configs from "../../utils/configs";
import {Buffer} from 'buffer';

import defaultProfilePic from "../../assets/images/profile/profile_default.png"

AWS.config.update({
  region: configs.auth.region,
  accessKeyId: configs.auth.accessKey,
  secretAccessKey: configs.auth.secretKey
});

export const ProfileInputField = memo(
  forwardRef(({ className, error, description, inputClassName, label, fullWidth, ...rest }, ref) => {
    const [showElement, setShowElement] = useState(false);
    const [erroMsg, setErrorMsg] = useState("");
    const [haveProfileImage, setHaveProfileImage] = useState(window.APP.livexr && window.APP.livexr.user && window.APP.livexr.user.profile_image != null & window.APP.livexr.user.profile_image != "");
    
    const id = useId();
    const labelId = useId();
    const inputId = useId();
    const inputRef = useRef(null);
    const imageRef = useRef(null);

    const getCurrentUserJwtToken = () => {
      return new Promise((resolve, reject) => {
        Auth.currentSession()
          .then(sessionData => {
            let jwtToken = sessionData.getIdToken().getJwtToken();
            let payload = sessionData.getIdToken().decodePayload();
            resolve({jwtToken, payload});
          })
          .catch(err => {
            reject("no-user");
          });
      })
    }

    getCurrentUserJwtToken().then(({jwtToken, payload}) => {
      setShowElement(true);
    }).catch((err) => {
      setShowElement(false);
    });

    const authPatch = (apiPath, apiParams, apiToken, apiCallback = null) => {
      let xhr = new XMLHttpRequest();
      xhr.open('PATCH', configs.auth.apiPath + '/' + apiPath);
      xhr.responseType = 'json';
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", apiToken);
      
      xhr.onload = function () {
        if (apiCallback != null) {
          apiCallback(xhr.response);
        }
      }
      xhr.send(JSON.stringify(apiParams));
    }

    const onInputChange = async (data) => {
      if (inputRef.current.files.length <= 0) {
        setErrorMsg("");
        return;
      }

      const maxFilesize = 1024; //KB
      if (inputRef.current.files.item(0).size >= maxFilesize * 1024) {
        setErrorMsg(<FormattedMessage id="avatar-settings-content.profile-image-limit-label" defaultMessage="Max size: 1MB" />);
        return;
      }

      const authData = await Auth.currentSession();
      const payload = authData.getIdToken().decodePayload();

      const updateProfileProcess = await new Promise((resolve) => {
        const filename = inputRef.current.files.item(0).name;
        const filesize = inputRef.current.files.item(0).size;
        const filetype = inputRef.current.files.item(0).type;

        const encodedString = Buffer.from(payload.sub).toString('base64');
        const encodedFilename = Buffer.from(Date.now().toString()).toString('base64');
        const extension = filename.split('.')[1];
        const profileKey = encodedString + "/" + encodedFilename + "." + extension;

        var bucket = new AWS.S3({
          region: configs.auth.region, 
          params: {
              Bucket: configs.auth.bucket
          },
        });
        var params = {
            Key: profileKey,
            ContentType: filetype,
            Body: inputRef.current.files.item(0)
        };

        setErrorMsg(<FormattedMessage id="avatar-settings-content.profile-image-uploading" defaultMessage="Uploading" />);
        // upload file
        bucket.upload(params).send(function(err, data) {
            if (err){
                console.log(err, err.stack);
                return;
            }

            authPatch("generaluser", {
              "sub": payload.sub,
              "user_name": payload.name,
              "email": payload.name,
              "nick_name": payload.nickname,
              "profile_image": profileKey
            },
            authData.getAccessToken().getJwtToken(), (result)=> {
              result["profileKey"] = profileKey;
              resolve(result);
            });
        });
      });

      handleProfileUpdateResult(updateProfileProcess);
    }

    const handleProfileUpdateResult = (result) => {
      imageRef.current.src = configs.auth.endpointPath + "/" + result.profileKey;
      if (result.statusCode == 200) {
        setErrorMsg("");
        setHaveProfileImage(true);
        imageRef.current.src = configs.auth.endpointPath + "/" + result.profileKey;
        window.APP.livexr.user.profile_image = result.profileKey;

        window.APP.store.update({
          profile: { profileImage: configs.auth.endpointPath + "/" + result.profileKey }
        });
      } else {
        setErrorMsg(<FormattedMessage id="avatar-settings-content.profile-image-system-error" defaultMessage="System Error" />);
      }
    }

    if (!showElement) {
      return <></>;
    }

    return (
      <>
        <InputField
          id={labelId}
          htmlFor={id}
          className={className}
          label={label}
          error={erroMsg}
          description={description}
          fullWidth={fullWidth}
        >
          <img style={{"width": "50px", "max-width": "50px", "height": "50px", "max-height": "50px", "margin": "0 auto"}} ref={imageRef} src={haveProfileImage? configs.auth.endpointPath + "/" + window.APP.livexr.user.profile_image: defaultProfilePic} />
        </InputField>
        <Button type="button" preset="basic" onClick={() => {inputRef.current.click();}}>
          <input id={inputId} type="file" accept=".jpg, .jpeg, .png" ref={inputRef} style={{width: "0px"}} onChange={onInputChange} />
          <FormattedMessage id="avatar-settings-content.change-profile-image-button" defaultMessage="Change" />
        </Button>
      </>
    );
  })
);

ProfileInputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.node,
  description: PropTypes.node,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  fullWidth: PropTypes.bool
};
