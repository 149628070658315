import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ToolbarViewModeButton.scss";

export const statusColors = ["ghost", "avatar"];

export const ToolbarViewModeButton = forwardRef(
  (
    { overrideStyle, className, iconContainerClassName, children, label, selected, large, statusColor, ...rest },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          styles.toolbarViewmodeButton,
          { [styles.selected]: selected, [styles.large]: large },
          className
        )}
        style={overrideStyle}
        {...rest}
      >
        <div className={classNames(styles.iconContainer, iconContainerClassName, styles["status-" + statusColor])} aria-hidden="true">
          {statusColor && <div className={classNames(styles.statusIndicator, styles["status-indicator-" + statusColor])} />}
          {children}
        </div>
        <label>{label}</label>
      </button>
    );
  }
);

ToolbarViewModeButton.propTypes = {
  label: PropTypes.node,
  selected: PropTypes.bool,
  statusColor: PropTypes.oneOf(statusColors),
  large: PropTypes.bool,
  className: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  children: PropTypes.node
};
