
import configs from "../utils/configs";
import {getHallSceneId} from "../utils/livexr-utils";

export async function getBestRoom(data, isMobile) {
  const sceneData = data.hubs[0].scene;
  var sceneID = sceneData.scene_id;
  const roomID = data.hubs[0].hub_id;

  const hallSceneID = getHallSceneId(sceneID);
  var isPlatformChange = false;

  //console.log("hallSceneID:", hallSceneID);

  //Platform変更が必要かどうかの判定
  if (configs.isLocalDevelopment) {
    //ローカル開発はlaptop設定にする
    sceneID = hallSceneID.laptop;
    isPlatformChange = false;
  } else {
    if (!(hallSceneID.mobile == ""  && hallSceneID.laptop == "")) {
      sceneID = isMobile? hallSceneID.mobile: hallSceneID.laptop;
    }
    isPlatformChange = (sceneID == sceneData.scene_id)? false: true;
  }
  //console.log("sceneID:", sceneID);

  //サーバーからルーム情報取得
  // console.log(endpoint);

  let needMoreRoomInfo = true;

  let rooms = null;
  let cursorNum = 1;
  while(needMoreRoomInfo) {

    let endpoint = location.origin + "/api/v1/media/search?source=rooms&filter=public&cursor=" + cursorNum;
    let response = await fetch(endpoint);
    let json = await response.json();
    if(rooms == null) {
      rooms = json.entries;
    } else {
    rooms = rooms.concat(json.entries);
    }
    needMoreRoomInfo = json.meta.next_cursor != null;
    cursorNum++;
    //console.log("api : " + endpoint);
  }

  // console.log("rooms:", rooms);
  // console.log("json:", json);
  //json.meta.next_cursor

  //sceneIDと一致しているルームを検出
  let filteredRooms = rooms.filter(function (el) {
    //console.log("filteredRooms, sceneID:", sceneID);
    return el.scene_id == sceneID;
  });

  //console.log("filteredRooms:", filteredRooms);

  let bestRoom = {};
  var NoRoomHasSpace = true;
  var isThisRoomHasSpace = false;
  var needRedirect = isPlatformChange? true: false; //Platform変更の場合は必ずRedirectが必要になります。
  filteredRooms.forEach(room => {
    if (isThisRoomHasSpace) {
      return;
    }

    //console.log("checkRoom:", room);
    //bestRoomがない時、最初のルームをbestRoomにします
    if (Object.keys(bestRoom).length === 0) {
      bestRoom = room;
    }

    let memberCount = room.member_count;
    let lobbyCount = room.lobby_count;
    let totalCount = memberCount + lobbyCount;
    let buffer = 0;
    let roomCapacity = room.room_size - buffer;

    if (!isPlatformChange) {
      //ユーザ一が既にルームに入っているため、自分のカウントを減らす
      if (roomID == room.id) {
        //console.log("totalCount--");
        totalCount --;
      }
    }
    
    if ((totalCount >= 0 && totalCount < roomCapacity)) {
      NoRoomHasSpace = false;
      bestRoom = room;
      //console.log("bestRoom:", bestRoom);
      if (!isPlatformChange) {
        if (roomID == room.id) {
          isThisRoomHasSpace = true;
        }
      }
    }
  });

  var url = "";
  if (isPlatformChange) {
    needRedirect = true;
    url = bestRoom.url;
  } else if (NoRoomHasSpace) {
    needRedirect = false;
    url = location.href;
  } else if (isThisRoomHasSpace) {
    needRedirect = false;
    url = location.href;
  } else {
    needRedirect = true;
    url = bestRoom.url;
  }
  //console.log("needRedirect:", needRedirect);
  //console.log("url:", url);
  return {requireRedirect: needRedirect, redirectURL: url};
};