import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ViewportList.scss";
import { ReactComponent as CloseIcon } from "../icons/CloseWhite.svg";
import { IconButton } from "../input/IconButton";

export function ViewportList({ children, headerNode, tagNode, contentClassName, className, type, onClose }) {
  return (
    <div className={classNames(type == "waypoint"? styles.viewportList:styles.viewportDocumentList, className)}>
      <div className={classNames(styles.viewportTop, contentClassName)}>
        {/*<IconButton onClick={onClose}>
          <CloseIcon width={30} height={30} />
        </IconButton>*/}
      </div>
      <div className={classNames(styles.viewportContent, contentClassName)}>
        <div className={classNames(styles.viewportContentInner, contentClassName)}>{headerNode}</div>
      </div>
      <div className={classNames(styles.viewportContent, contentClassName)}>
        <div className={classNames(styles.viewportContentInner, contentClassName)}>{tagNode}</div>
      </div>
      <div className={classNames(styles.viewportContent, contentClassName)}>
        <div className={classNames(styles.viewportContentInner, contentClassName)}>{children}</div>
      </div>
      <div className={classNames(styles.viewportBottom, contentClassName)}></div>
    </div>
  );
}

ViewportList.propTypes = {
  children: PropTypes.node,
  headerNode: PropTypes.node,
  tagNode: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string
};
