import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import eventDataJson from "../../assets/json/event_dialog.json"
import styles from "./EventDialog.scss";

import { EventDialogToolbarButton } from "./EventDialogComponent";

export class EventDialogContainer extends Component {
  static propTypes = {
    sceneId: PropTypes.string,
    onClose: PropTypes.func
  };

  state = {
    eventData: {}
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      eventData: eventDataJson.eventData.filter((data) => {
        const dataStartDate = new Date(data.startDate);
        const dataEndDate = new Date(data.endDate);
        const currentDate = new Date();
        if (currentDate >= dataStartDate && currentDate <= dataEndDate && data.sceneId == this.props.sceneId) {
          return true;
        } else {
          return false;
        }
      })
    })
  }

  render() {
    const isMobile = AFRAME.utils.device.isMobile();

    if (this.state.eventData && this.state.eventData.length > 0) {
      const eventData = this.state.eventData[0];
      return (
        <div className={styles.EventDialogContatiner}>
          <div className={classNames(styles.EventDialogBgContatiner, styles.EventDialogTitleContatiner)}>
            {eventData.title}
          </div>
          
          <div className={styles.EventDialogBgContatiner}>
            <div className={styles.EventDialogContentContatiner}>{eventData.content}</div>
            <div className={styles.EventDialogControlContatiner}>
              {
                eventData.url? 
                  <div className={classNames(styles.EventDialogButtonContatiner, styles.EventDialogButtonDetail)} onClick={e => {
                    window.open(eventData.url, '_blank')
                  }}>イベント詳細</div>
                :
                  <></>
              }
              <div className={classNames(styles.EventDialogButtonContatiner, styles.EventDialogButtonExit)} onClick={e => {
                this.props.onClose();
              }}>閉じる</div>
            </div>
            
          </div>
        </div>
      );
    }
    return (
      <></>
    );
  }
}

export function EventDialogToolbarButtonContainer(props) {
  return <EventDialogToolbarButton {...props} />
}

export function HaveEventData(sceneId) {
  const data = eventDataJson.eventData.filter((data) => {
    const dataStartDate = new Date(data.startDate);
    const dataEndDate = new Date(data.endDate);
    const currentDate = new Date();
    if (currentDate >= dataStartDate && currentDate <= dataEndDate && data.sceneId == sceneId) {
      return true;
    } else {
      return false;
    }
  })
  return (data && data.length > 0);
}
