import React, { forwardRef } from "react";
import { ReactComponent as CameraIcon } from "../icons/Camera.svg";
import { ToolbarButton } from "../input/ToolbarButton";
import styles from "./SelfieCamera.scss";
import { FormattedMessage, useIntl, defineMessages, FormattedRelativeTime } from "react-intl";

export function SelfieCameraToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={<CameraIcon />}
      preset="accent5"
      label={<FormattedMessage id="selfie-camera-toolbar-button" defaultMessage="Camera" />}
      statusColor={props.isOn? "enabled":　"disabled"}
    />
  );
}
