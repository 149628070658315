import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  WaypointSidebar,
  WaypointViewport,
  WaypointTagList,
  WaypointTagListElement,
  WaypointList,
  WaypointViewportList,
  WaypointElement,
  WaypointHeaderElement,
  WaypointToolbarButton,
  WaypointViewportButton
} from "./WaypointSidebar";
import { useMaintainScrollPosition } from "../misc/useMaintainScrollPosition";
import { FormattedMessage, useIntl } from "react-intl";
import { getWaypointList, hasWaypointTargetGroupList } from "../../utils/livexr-utils";

const WaypointContext = createContext({ waypointGroups: []});

export function WaypointSidebarContainer({ scene, onClose }) {
  //const { waypointGroups } = useContext(WaypointContext);
  const sceneId = window.APP.hub.scene && window.APP.hub.scene.scene_id? window.APP.hub.scene.scene_id: "";
  const waypointGroups = getWaypointList(sceneId);
  const [onScrollList, listRef, scrolledToBottom] = useMaintainScrollPosition(waypointGroups);
  const intl = useIntl();

  return (
    <WaypointSidebar onClose={onClose}>
      <WaypointList ref={listRef} onScroll={onScrollList}>
        {waypointGroups.map((record) => {
          return <WaypointElement key={"boothNo" + record.boothNo} {...record} />;
        })}
      </WaypointList>
    </WaypointSidebar>
  );
}

WaypointSidebarContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export function WaypointViewportContainer({ type, scene, onClose }) {
  const sceneId = window.APP.hub.scene && window.APP.hub.scene.scene_id? window.APP.hub.scene.scene_id: "";

  const hasSiteList = hasWaypointTargetGroupList(sceneId, 2);
  const hasRoomList = hasWaypointTargetGroupList(sceneId, 3);
  const hasResourceList = hasWaypointTargetGroupList(sceneId, 4);
  const hasVideoList = hasWaypointTargetGroupList(sceneId, 5);

  const waypointGroups = getWaypointList(sceneId);
  const [targetgroupId, setTargetgroupId] = useState(type == "waypoint"? 3: hasSiteList > 0? 2: (hasResourceList > 0? 4: 5));

  const updateTargetGroupId = (value) => {
    setTargetgroupId(value);
  }

  var borderColor = "";
  switch (targetgroupId) {
    case 2: borderColor="#91CA8C"; break;
    case 3: borderColor="#77C8F1"; break;
    case 4: borderColor="#FFB780"; break;
    case 5: borderColor="#FFFB80"; break;
    default: borderColor="#77C8F1"; break;
  }

  return (
    
      <WaypointViewport onClose={onClose}
        type={type}
        headerNode={
          type == "waypoint"? 
          <WaypointTagList>
            {
              hasRoomList > 0 && <WaypointTagListElement onClick={() => updateTargetGroupId(3)} title={<FormattedMessage id="waypoint-tag.room" defaultMessage="Room" />} borderColor="#77C8F1"></WaypointTagListElement>
            }
          </WaypointTagList>:
          <WaypointTagList>
            {
              hasSiteList > 0 && <WaypointTagListElement onClick={() => updateTargetGroupId(2)} title={<FormattedMessage id="waypoint-tag.site" defaultMessage="Site" />} borderColor="#91CA8C"></WaypointTagListElement>
            }
            {
              hasResourceList > 0 && <WaypointTagListElement onClick={() => updateTargetGroupId(4)} title={<FormattedMessage id="waypoint-tag.resource" defaultMessage="Resources" />} borderColor="#FFB780"></WaypointTagListElement>
            }
            {
              hasVideoList > 0 && <WaypointTagListElement onClick={() => updateTargetGroupId(5)} title={<FormattedMessage id="waypoint-tag.video" defaultMessage="Video" />} borderColor="#FFFB80"></WaypointTagListElement>
            }
          </WaypointTagList>
        }
        tagNode={
          <WaypointViewportList>
            <WaypointHeaderElement borderColor={borderColor}></WaypointHeaderElement>
          </WaypointViewportList>
        }
      
      >
        <>
          <WaypointViewportList>
            {waypointGroups.filter(data => data.boothType == targetgroupId).map((record, index) => {
              return <WaypointElement key={"boothNo" + record.boothNo} countIndex={index} borderColor={borderColor} {...record} />;
            })}
          </WaypointViewportList>
        </>
      </WaypointViewport>
  );
}

WaypointViewportContainer.propTypes = {
  type: PropTypes.string,
  scene: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};


export function WaypointToolbarButtonContainer(props) {
  const sceneId = window.APP.hub.scene && window.APP.hub.scene.scene_id? window.APP.hub.scene.scene_id: "";
  const waypointGroups = getWaypointList(sceneId);
  
  return waypointGroups.length > 0? <WaypointToolbarButton {...props} />: <></>;
}

export function WaypointViewportButtonContainer(props) {
  const sceneId = window.APP.hub.scene && window.APP.hub.scene.scene_id? window.APP.hub.scene.scene_id: "";
  const waypointGroups = getWaypointList(sceneId);
  // External Site: 2
  // Room: 3
  // Resoures: 4
  // Video: 5
  const hasSiteList = hasWaypointTargetGroupList(sceneId, 2);
  const hasRoomList = hasWaypointTargetGroupList(sceneId, 3);
  const hasResourceList = hasWaypointTargetGroupList(sceneId, 4);
  const hasVideoList = hasWaypointTargetGroupList(sceneId, 5);
  var isDisplayButton = false;

  if (props.type == "waypoint") {
    isDisplayButton = waypointGroups.length > 0 && hasRoomList > 0;
  } else {
    isDisplayButton = waypointGroups.length > 0 && (hasSiteList > 0 || hasResourceList > 0 || hasVideoList > 0);
  }
  
  return isDisplayButton? <WaypointViewportButton {...props} />: <></>;
}