import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import PropTypes from "prop-types";
import classNames from "classnames";

import { Auth } from 'aws-amplify';
import { LiveXRLoginPage } from '../react-components/livexr-auth/LiveXRLoginPage';
import { LiveXRRegistrationPage } from '../react-components/livexr-auth/LiveXRRegistrationPage';
import { LiveXRForgotPasswordPage } from '../react-components/livexr-auth/LiveXRForgotPasswordPage';
import { LiveXRForgotPasswordSubmitPage } from '../react-components/livexr-auth/LiveXRForgotPasswordSubmitPage';
import { LiveXRVerifyPage } from '../react-components/livexr-auth/LiveXRVerifyPage';

const getCurrentUserJwtToken = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(sessionData => {
        let jwtToken = sessionData.getIdToken().getJwtToken();
        resolve(jwtToken);
      })
      .catch(err => {
        reject("no-user");
      });
  })
}

AuthPage.propTypes = {
  isDefaultVerify: PropTypes.bool,
  isDefaultForgetPasswordSubmit: PropTypes.bool
};

AuthPage.defaultProps = {
  isDefaultVerify: false,
  isDefaultForgetPasswordSubmit: false
}

function AuthPage({isDefaultVerify, isDefaultForgetPasswordSubmit}) {
  const [loginState, setLoginState] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [isShowLogin, setIsShowLogin] = useState(isDefaultVerify || isDefaultForgetPasswordSubmit? false: true);
  const [isShowForgotPassword, setIsShowForgotPassword] = useState(false);
  const [isShowForgotPasswordSubmit, setIsShowForgotPasswordSubmit] = useState(isDefaultForgetPasswordSubmit? true: false);
  const [isShowVerify, setIsShowVerify] = useState(isDefaultVerify? true: false);
  useEffect(() => {
    getCurrentUserJwtToken().then((jwttoken) => {
      setLoginState(true);
      setLoadingState(false);
    }).catch((err) => {
      setLoginState(false);
      setLoadingState(false);
    });
  })

  const onLoginSuccessCallback = () => {
    setLoginState(true);
    setLoadingState(false);
    location.reload();
  }

  const onRegSuccessCallback = () => {
    setLoginState(false);
    setLoadingState(false);
    setIsShowLogin(false);
    setIsShowVerify(true);
  }

  const onForgetPasswordSuccessCallback = () => {
    console.log("onForgetPasswordSuccessCallback");
    setIsShowForgotPasswordSubmit(true);
    setIsShowForgotPassword(false);
    setLoginState(false);
    setLoadingState(false);
    setIsShowLogin(true);
  }

  const onForgetPasswordSubmitSuccessCallback = () => {
    console.log("onForgetPasswordSubmitSuccessCallback");
    setIsShowForgotPasswordSubmit(false);
    setLoginState(false);
    setLoadingState(false);
    setIsShowLogin(true);
  }

  const onVerifySuccessCallback = () => {
    console.log("onVerifySuccessCallback");
    setIsShowVerify(false);
    setLoginState(false);
    setLoadingState(false);
    setIsShowLogin(true);
  }

  const showVerifyAfterLogin = (defaultEmail) => {
    setIsShowVerify(true);
    setLoginState(false);
    setLoadingState(false);
    setIsShowLogin(false);
  }

  if (loadingState) {
    return (
      <h2>loading...</h2>
    )
  }
  
  if (!loginState) {
    if (isShowVerify) {
      return <LiveXRVerifyPage verifySuccessCallback={() =>  onVerifySuccessCallback() } onClickLogin={() => {setIsShowVerify(false); setIsShowLogin(true)}} />;
    }
    if (isShowForgotPassword) {
      return <LiveXRForgotPasswordPage forgetPasswordSuccessCallback={() =>  onForgetPasswordSuccessCallback() } onClickLogin={() => {setIsShowForgotPassword(false); setIsShowLogin(true)}} />;
    }
    if (isShowForgotPasswordSubmit) {
      return <LiveXRForgotPasswordSubmitPage forgetPasswordSubmitSuccessCallback={() =>  onForgetPasswordSubmitSuccessCallback() } onClickLogin={() => {setIsShowForgotPasswordSubmit(false); setIsShowLogin(true)}} />;
    }
    if (isShowLogin) {
      return <LiveXRLoginPage loginSuccessCallback={() =>  onLoginSuccessCallback() } onClickReg={() => {setIsShowForgotPassword(false); setIsShowLogin(false)}} onClickForgetPassword={() => {console.log("onClickForgetPassword"); setIsShowForgotPassword(true); setIsShowLogin(false)}} verifyNeedCallback={() => {showVerifyAfterLogin()}} />;
    } else {
      return <LiveXRRegistrationPage regSuccessCallback={() =>  onRegSuccessCallback() } onClickLogin={() => {setIsShowForgotPassword(false); setIsShowLogin(true)}} />;
    }
  }
  
  return <></>;
}

export default AuthPage