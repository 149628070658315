import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MultiScreenShareSidebar,
  MultiScreenShareList,
  MultiScreenShareElement,
  MultiScreenShareToolbarButton,
} from "./MultiScreenShareSidebar";
import { useMaintainScrollPosition } from "../misc/useMaintainScrollPosition";
import { useIntl } from "react-intl";
import { getExtraSetting } from "../../utils/livexr-utils";

export function MultiScreenShareSidebarContainer({ scene, onClose, shareCamera }) {
  const extraSetting = getExtraSetting();
  const multiScreenShareGroups = extraSetting && extraSetting.fixVideoShare && extraSetting.fixShareTransformList? extraSetting.fixShareTransformList: [];

  //extraSetting && extraSetting.fixVideoShare && extraSetting.fixShareTransformList;
  const [onScrollList, listRef, scrolledToBottom] = useMaintainScrollPosition(multiScreenShareGroups);
  const intl = useIntl();

  return (
    <MultiScreenShareSidebar onClose={onClose}>
      <MultiScreenShareList ref={listRef} onScroll={onScrollList}>
        {multiScreenShareGroups.map((record, index) => {
          return <MultiScreenShareElement onShare={onClose} shareCamera={shareCamera} scene={scene} key={"multiScreenShare" + index} {...record} />;
        })}
      </MultiScreenShareList>
    </MultiScreenShareSidebar>
  );
}

MultiScreenShareSidebarContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  shareCamera: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired
};

export function MultiScreenShareToolbarButtonContainer(props) {
  const extraSetting = getExtraSetting();
  const multiScreenShareGroups = extraSetting && extraSetting.fixVideoShare && extraSetting.fixShareTransformList? extraSetting.fixShareTransformList: [];

  return multiScreenShareGroups.length > 0? <MultiScreenShareToolbarButton {...props} />: <></>;
}
