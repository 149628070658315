import configs from "./configs";
import merge from "deepmerge";
import { EventTarget } from "event-target-shim";

export default class Role extends EventTarget{
  isRoleLoading = false;

  state = {
    ui: configs.ui,
    utils: configs.utils
  };

  constructor() {
    super();
  }

  updateByRole(userRole, roomPermission) {
    if (!roomPermission) {
      return;
    }

    let tempPermission = {
      ui: {
        showShareScreen: false,
        showShareCamera: false,
        showMic: false
      },
      
      utils: {
        allowFullRoomVoice: true
      }
    }

    const tempRoomPermission = roomPermission? roomPermission :{
      allowScreenShare: ["事務局"],
      allowVoice: ["事務局"],
      allowShowAvatar: ["事務局"]
    }

    if (tempRoomPermission.allowVoice && tempRoomPermission.allowVoice.includes(userRole)) {
      tempPermission.ui.showMic = true;
      tempPermission.utils.allowFullRoomVoice = true;
    } else {
      tempPermission.ui.showMic = false;
      tempPermission.utils.allowFullRoomVoice = false;
    }
    if (tempRoomPermission.allowScreenShare && tempRoomPermission.allowScreenShare.includes(userRole)) {
      tempPermission.ui.showShareCamera = true;
      tempPermission.ui.showShareCamera = true;
    } else {
      tempPermission.ui.showShareCamera = false;
      tempPermission.ui.showShareCamera = false;
    }

    const newPermission = tempPermission;

    this.update(newPermission);
  }

  update(newState, mergeOpts) {
    const finalState = merge(this.state, newState, mergeOpts);
    this.state = finalState;
    return finalState;
  }

  getUiPermissions = () => {
    return this.state.ui;
  }

  getUtilsPermissions = () => {
    return this.state.utils;
  }

  getUiPermission = (permissionStr) => {
    return this.state.ui[permissionStr];
  }

  getUtilsPermission = (permissionStr) => {
    return this.state.utils[permissionStr];
  }

  roleGet(apiPath, apiParams, apiCallback = null){
    this.isRoleLoading = true;
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('GET', configs.url.liveXRApiBase + '/' + apiPath);
    xhr.responseType = 'json';
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
      this.isRoleLoading = false;
      if (apiCallback != null) {
        apiCallback(xhr.response);
      }
    };
    xhr.send(JSON.stringify(apiParams));
  }
  
  rolePatch(apiPath, apiParams, apiCallback = null){
    if (this.isRoleLoading == true){
      return false;
    }
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('PATCH', configs.url.liveXRApiBase + '/role/' + apiPath);
    xhr.responseType = 'json';
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onload = function () {
      if (apiCallback != null) {
        apiCallback(xhr.response);
      }
    }
    xhr.send(JSON.stringify(apiParams));
  }
  
  roleDelete(apiPath, apiParams, apiCallback = null){
    if (isRoleLoading == true){
      return false;
    }
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('DELETE', configs.url.liveXRApiBase + '/' + apiPath);
    xhr.responseType = 'json';
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onload = function () {
      if (apiCallback != null) {
        apiCallback(xhr.response);
      }
    }
    xhr.send(JSON.stringify(apiParams));
  }
  
  rolePost(apiPath, apiParams, apiCallback = null){
    if (this.isRoleLoading == true){
      return false;
    }
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('POST', configs.url.liveXRApiBase + '/' + apiPath);
    xhr.responseType = 'json';
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onload = function () {
      if (apiCallback != null) {
        apiCallback(xhr.response);
      }
    }
    xhr.send(JSON.stringify(apiParams));
  }
}