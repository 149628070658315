import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Sidebar } from "../sidebar/Sidebar";
import { CloseButton } from "../input/CloseButton";
import { ReactComponent as ChatIcon } from "../icons/Chat.svg";
import { IconButton } from "../input/IconButton";
import { ToolbarButton } from "../input/ToolbarButton";
import styles from "./MultiScreenShareSidebar.scss";
import { FormattedMessage, useIntl, defineMessages, FormattedRelativeTime } from "react-intl";



function shareScreen(scene, shareCamera, onShare, transformData) {
  onShare();
  if (shareCamera) {
    scene.emit("action_share_camera_with_transform", transformData);
  } else {
    scene.emit("action_share_screen_with_transform", transformData);
  }
  
}

export function MultiScreenShareElement(props) {
  const transformData = {
    "pos": props.pos,
    "rot": props.rot,
    "scale": props.scale,
  }
  return (
    <ul className={styles.listRow}>
      <li className={classNames(styles.listCell)}>{props.name}</li>
      <li className={classNames(styles.listCell, styles.multiScreenShareIcon)}><div onClick={() => shareScreen(props.scene, props.shareCamera, props.onShare, transformData)}>Share</div></li>
    </ul>
  );
}

MultiScreenShareElement.propTypes = {
  sent: PropTypes.bool,
  sender: PropTypes.string,
  timestamp: PropTypes.any,
  messages: PropTypes.array,
  scene: PropTypes.object,
  onShare: PropTypes.func,
  shareCamera: PropTypes.bool
};

export const MultiScreenShareList = forwardRef(({ children, ...rest }, ref) => (
  <div {...rest} className={styles.listTable} ref={ref}>
    <ul className={classNames(styles.listRow, styles.listRowHeader)}>
      <li className={classNames(styles.listCell)}>Name</li>
      <li className={classNames(styles.listCell, styles.multiScreenShareIcon)}>Icon</li>
    </ul>
    {children}
  </div>
));

MultiScreenShareList.propTypes = {
  children: PropTypes.node
};

export function MultiScreenShareSidebar({ onClose, children, ...rest }) {
  return (
    <Sidebar
      title={<FormattedMessage id="multi-screen-share-sidebar.title" defaultMessage="Screen Share" />}
      beforeTitle={<CloseButton onClick={onClose} />}
      contentClassName={styles.content}
      {...rest}
    >
      {children}
    </Sidebar>
  );
}

MultiScreenShareSidebar.propTypes = {
  onClose: PropTypes.func,
  onScrollList: PropTypes.func,
  children: PropTypes.node,
  listRef: PropTypes.func
};

export function MultiScreenShareToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={<ChatIcon />}
      preset="green"
      label={<FormattedMessage id="multi-screen-share-toolbar-button" defaultMessage="Screen Share" />}
    />
  );
}
