import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope,*/ faUser, faLock, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope/*, faUser, faLock, faArrowRight*/ } from '@fortawesome/free-regular-svg-icons'
import { Auth } from 'aws-amplify';

import styles from "./LiveXRRegistrationPage.scss";
import configs from "../../utils/configs";
import { termsContentInner } from "./LiveXRRegistrationTerms"
import logo from "../../assets/images/logo/logo.png";
import step0 from "../../assets/images/Login/steps/step0.png";
import step2 from "../../assets/images/Login/steps/step2.png";

LiveXRRegistrationPage.propTypes = {
  regSuccessCallback: PropTypes.func,
  onClickLogin: PropTypes.func
};

export function LiveXRRegistrationPage({regSuccessCallback, onClickLogin}) {
  const [regErrorState, setRegErrorState] = useState(false);
  const [regErrorMsg, setRegErrorMsg] = useState("");
  const [regStepState, setRegStepState] = useState(0);
  const inputRef_userName = useRef(null);
  const inputRef_password = useRef(null);
  const inputRef_email = useRef(null);
  const inputRef_confirmpassword = useRef(null);
  
  const pwPattern = "^(?=.*[a-z])(?=.*\\d)[A-Za-z\\d!\"#$%&'()=~|\\-^\\\\`{+*}<>?_@\\[;:\\],.\\/ ]{8,}$";
  const authPost = (apiPath, apiParams, apiCallback = null) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', configs.auth.apiPath + '/' + apiPath);
    xhr.responseType = 'json';
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onload = function () {
      if (apiCallback != null) {
        apiCallback(xhr.response);
      }
    }
    xhr.send(JSON.stringify(apiParams));
  }

  const signUp = async () => {
    const signUpProcess = await new Promise((resolve) => {
      if (!checkAllValidate()) {
        return;
      }

      setRegStepState(3);
      
      authPost("generaluser", {
        "user_name": inputRef_email.current.value,
        "email": inputRef_email.current.value,
        "password": inputRef_password.current.value,
        "nick_name": inputRef_userName.current.value
      }, (result)=> {
        resolve(result);
      });
    });

    handleRegResult(signUpProcess);
  }

  const handleRegResult = (result) => {
    if (result.statusCode == 200) {
      setRegStepState(4);
      setRegErrorState(false);
      setRegErrorMsg("");

      //regSuccessCallback();
    } else if (result.statusCode == 400) {
      setRegErrorState(true);
      setRegStepState(2);
      if (result.body.message == "This username already exists") {
        setRegErrorMsg("このメールアドレスは登録済みです。");
      } else {
        setRegErrorMsg("システムエラー");
      }
    }
  }

  const login = async () => {
    onClickLogin();
  }

  const checkValidate = (e) => {
    checkElemValidate(e.target);
  }

  const errMessage = {
    "email": {
      "typeMismatch": "メールアドレスの入力形式が正しくありません。",
      "valueMissing": "メールアドレスを入力してください。"
    },
    "displayname": {
      "patternMismatch": "表示名を全角16文字または半角32文字以下で入力してください。",
      "valueMissing": "表示名を入力してください。"
    },
    "password": {
      "valueMissing": "パスワードを入力してください。",
      "patternMismatch": "パスワードは半角英字と数字をどちらも含む8文字以上にする必要があります。"
    },
    "term": {
      "valueMissing": "利用規約を確認後、チェックしてください。"
    },
    "privacy": {
      "valueMissing": "プライバシーポリシーを確認後、チェックしてください。"
    },
    "confirmpassword": {
      "valueMissing": "パスワードを入力してください。",
      "patternMismatch": "パスワードは半角英字と数字をどちらも含む8文字以上にする必要があります。",
      "customError": "パスワードが一致していません。"
    }
  }

  const checkElemValidate = (e) => {
    var isValid = true;
    e.classList.remove(styles.inputError);           // 2022.07/04 追加分(Tam)  

    var errElem = document.querySelector("[name=\"err_"+e.getAttribute("validatorname")+"\"]");
    const passwordElem = document.querySelector("[validatorname=\"password\"]");

    if (e.validity.typeMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["typeMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["typeMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);           // 2022.07/04 追加分(Tam)  
    } else if (e.validity.valueMissing) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["valueMissing"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["valueMissing"]);
      isValid = false;
      e.classList.add(styles.inputError);           // 2022.07/04 追加分(Tam)  
    } else if(e.validity.patternMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["patternMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["patternMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);           // 2022.07/04 追加分(Tam)  
    } else if (e.getAttribute("validatorname") === "confirmpassword" && passwordElem.value != e.value) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["customError"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["customError"]);
      isValid = false;
      e.classList.add(styles.inputError);           // 2022.07/04 追加分(Tam)  
    } else {
      errElem.innerHTML = "";
      e.setCustomValidity("");
    }
    
    return isValid;
  }

  const checkAllValidate = (e) => {
    var isValid = true;
    document.querySelectorAll("input").forEach((elem) => {
      isValid = checkElemValidate(elem) && isValid;
    });

    const passwordElem = document.querySelector("[validatorname=\"password\"]");
    const passwordComfirmElem = document.querySelector("[validatorname=\"confirmpassword\"]");

    if (passwordElem && passwordComfirmElem && passwordElem.value != passwordComfirmElem.value) {
      passwordComfirmElem.innerHTML = errMessage["confirmpassword"]["customError"];
      passwordComfirmElem.setCustomValidity(errMessage["confirmpassword"]["customError"]);
      isValid = false;
    }

    return isValid;
  }

  const handleTermNextButton = () => {
    if (!checkAllValidate()) {
      return;
    }
    
    setRegStepState(2);
    const checkboxElem = document.querySelector("[validatorname=\"term\"]");
    checkboxElem.value = false;
  }

  // const handlePrivacyNextButton = () => {
  //   if (!checkAllValidate()) {
  //     return;
  //   }
  //   setRegStepState(2);
  // }

  const termsContent = (
    <div>
      <div className={classNames(styles.steps)}>
      <img src={step0} />
      </div>
      { termsContentInner }
      <div className={classNames(styles.checkboxDiv)}>
        <label for="chkboxTerm">
          <p className={classNames(styles.checkbox)}><input id="chkboxTerm" validatorname="term" type="checkbox" key={"term" + Math.random()} required defaultChecked={false} className={classNames(styles.checkboxbox)} /></p>
          <p className={classNames(styles.checkLabel)}>サービスの利用規約（プライバシーポリシーを含む）を読み、同意します。</p>
        </label>
      </div>
      <div name="err_term" className={classNames(styles.errorMessageForCheck, styles.center)}></div>
      <div className={classNames(styles.nextButtonDiv)}>
        <button type="button" className={classNames(styles.nextButton)} onClick={handleTermNextButton}>次へ</button>
      </div>
    </div>
  );


  const regForm = (
    <div>
    <div className={classNames(styles.steps)}>
    <img src={step2} />
    </div>
    <div className={classNames(styles.formDiv)}>
      <form className={classNames(styles.form)} noValidate>
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faUser} />
            <input validatorname="displayname" onChange={checkValidate} ref={inputRef_userName} type="text" placeholder="表示名" className={classNames(styles.inputField, styles.name)} required pattern="^.{1,32}$" />
          </div>
          <div name="err_displayname" className={classNames(styles.errorMessageForInput)}></div>
        </div>
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faEnvelope} />
            <input validatorname="email" onChange={checkValidate} ref={inputRef_email} type="email" placeholder="メールアドレス" className={classNames(styles.inputField, styles.name)} required />
          </div>
          <div name="err_email" className={classNames(styles.errorMessageForInput)}></div>
        </div>

        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)} >  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faLock} />
            <input validatorname="password" onChange={checkValidate} ref={inputRef_password} type="password" placeholder="パスワード" className={classNames(styles.inputField, styles.password)} required pattern={pwPattern} />
          </div>
          <div name="err_password" className={classNames(styles.errorMessageForInput)}></div>
        </div>
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)} >  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faLock} />
            <input validatorname="confirmpassword" onChange={checkValidate} ref={inputRef_confirmpassword} type="password" placeholder="パスワード(確認用)" className={classNames(styles.inputField, styles.password)} required pattern={pwPattern} />
          </div>
          <div name="err_confirmpassword" className={classNames(styles.errorMessageForInput)}></div>
        </div>
      </form>
      <div className={classNames(styles.errorMessageForInput)}>{regErrorState? regErrorMsg: ""}</div>
      <div className={classNames(styles.signupButtonDiv)}>
        <button type="button" className={classNames(styles.regButton)} onClick={signUp}>新規登録</button>
      </div>
      <div className={classNames(styles.notice)}>
        <p>※ 一度登録したメールアドレスは変更できません。</p>
        <p>※ ｢noreply@mail.meta-messe.com｣ からのメールを受信できるようにご設定ください。</p>
      </div>
    </div>
    </div>

  );

  const registratingContent = (
    <div>
      <div className={classNames(styles.successDiv)}>      
          <p>登録中です。</p>
          <p>少々お待ちください...</p>
      </div>
    </div>
  );

  const successContent = (
    <div>
      <div className={classNames(styles.successDiv)}>      
        <p>認証コードをメールへ送信しました。<br  className={classNames(styles.br)}/>
        続いて登録してください。</p>
      </div>
      <div className={classNames(styles.signupButtonDiv)}>
        <button type="submit" className={classNames(styles.loginButton)} onClick={() => {regSuccessCallback()}}>確定</button>
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.content)}>
      {/* <div className={classNames(styles.logo)}><img src={logo} /></div> */}
      <div className={classNames(styles.reg)}>
        <div className={classNames(styles.shinkiToroku)}>
          新規登録
        </div>
        {
          (regStepState == 0)? termsContent: 
          (regStepState == 1)? privacyContent:
          (regStepState == 2)? regForm:
          (regStepState == 3)? registratingContent:
          successContent
        }        
      </div>
      {
        regStepState == 3 || regStepState == 4? <></>: (<div className={classNames(styles.goToLoginButtonDiv)}>
          <button type="submit" className={classNames(styles.loginButton)} onClick={login}>アカウントをお持ちの方は</button>
        </div>)
      }
      <div className={classNames(styles.height20)}></div>
      <footer>
      <div className={classNames(styles.logo)}><img src={logo} /></div>
      </footer>
    </div>
  );
}
