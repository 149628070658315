import configs from "../utils/configs";
import { getPresenceEntryForSession } from "../utils/phoenix-utils";
import { AudioType, AvatarAudioDefaults, MediaAudioDefaults } from "../components/audio-params";

function updateMediaAudioSettings(mediaVideo, settings) {
  mediaVideo.el.setAttribute("audio-params", {
    distanceModel: settings.mediaDistanceModel,
    rolloffFactor: settings.mediaRolloffFactor,
    refDistance: settings.mediaRefDistance,
    maxDistance: settings.mediaMaxDistance,
    coneInnerAngle: settings.mediaConeInnerAngle,
    coneOuterAngle: settings.mediaConeOuterAngle,
    coneOuterGain: settings.mediaConeOuterGain,
    gain: settings.mediaVolume
  });
}

function updateAvatarAudioSettings(avatarAudioSource, settings, positional = undefined) {
  avatarAudioSource.el.setAttribute("audio-params", {
    distanceModel: settings.avatarDistanceModel,
    maxDistance: settings.avatarMaxDistance,
    refDistance: settings.avatarRefDistance,
    rolloffFactor: settings.avatarRolloffFactor,
    coneInnerAngle: settings.avatarConeInnerAngle,
    coneOuterAngle: settings.avatarConeOuterAngle,
    coneOuterGain: settings.avatarConeOuterGain
  });

  // console.log("audioType", positional);
  // if (positional !== undefined) {
  //   avatarAudioSource.el.setAttribute("audio-params", {
  //     audioType: positional ? AudioType.PannerNode : AudioType.Stereo
  //   });
  // }
}

export class AudioSettingsSystem {
  constructor(sceneEl) {
    sceneEl.addEventListener("reset_scene", this.onSceneReset);

    // HACK We are scared that users are going to set this preference and then
    // forget about it and have a bad time, so we always remove the preference
    // whenever the user refreshes the page.
    // TODO: This is pretty weird and surprising. If the preference is exposed
    // in the preference screen, then we would not be so scared about this.
    // Also, if we feel so concerned about people using it, we should consider
    // ways to make it safer or remove it.
    window.APP.store.update({
      preferences: { audioOutputMode: undefined }
    });

    // TODO: Remove these hacks
    if (
      !window.APP.store.state.preferences.audioOutputMode ||
      window.APP.store.state.preferences.audioOutputMode === "audio"
    ) {
      //hack to always reset to "panner"
      window.APP.store.update({
        preferences: { audioOutputMode: "panner" }
      });
    }
    if (window.APP.store.state.preferences.audioNormalization !== 0.0) {
      //hack to always reset to 0.0 (disabled)
      window.APP.store.update({
        preferences: { audioNormalization: 0.0 }
      });
    }

    this.audioOutputMode = window.APP.store.state.preferences.audioOutputMode;
    this.onPreferenceChanged = () => {
      const newPref = window.APP.store.state.preferences.audioOutputMode;
      const shouldUpdateAudioSettings = this.audioOutputMode !== newPref;
      this.audioOutputMode = newPref;
      if (shouldUpdateAudioSettings) {
        this.updateAudioSettings(this.audioSettings);
      }
    };
    window.APP.store.addEventListener("statechanged", this.onPreferenceChanged);
  }

  registerMediaAudioSource(mediaVideo) {
    const index = this.mediaVideos.indexOf(mediaVideo);
    if (index === -1) {
      this.mediaVideos.push(mediaVideo);
    }
    updateMediaAudioSettings(mediaVideo, this.audioSettings);
  }

  unregisterMediaAudioSource(mediaVideo) {
    this.mediaVideos.splice(this.mediaVideos.indexOf(mediaVideo), 1);
  }

  registerAvatarAudioSource(avatarAudioSource) {
    const index = this.avatarAudioSources.indexOf(avatarAudioSource);
    if (index === -1) {
      this.avatarAudioSources.push(avatarAudioSource);
    }
    if (this.sceneEl.is("entered")) {
      this.createAvatarAudioSource(avatarAudioSource);
    } else {
      this.sceneEl.addEventListener("enterScene", () => {
        this.createAvatarAudioSource(avatarAudioSource);
      });
    }
  }

  createAvatarAudioSource(avatarAudioSource) {
    NAF.utils.getNetworkedEntity(avatarAudioSource.el).then(networkedEl => {
      this.networkedEl = networkedEl;
      const uuid = networkedEl.object3D.el.firstUpdateData.creator;
      const people = window.APP.people;

      const target = people.find(x => x.id === uuid);

      const me = people.find(x => x.id !== uuid);
      const amIAdmin = me.presence.metas[0].profile.isAdmin;
      const isTargetAdmin = target.presence.metas[0].profile.isAdmin;
      const positional = (amIAdmin || !isTargetAdmin) && window.APP.store.state.preferences.audioOutputMode !== "audio";
      avatarAudioSource.createAudio(positional);
      updateAvatarAudioSettings(avatarAudioSource, this.audioSettings, positional);
    });
  }

  unregisterAvatarAudioSource(avatarAudioSource) {
    const index = this.avatarAudioSources.indexOf(avatarAudioSource);
    if (index !== -1) {
      this.avatarAudioSources.splice(index, 1);
    }
  }

  updateAvatarAudioSource(avatarAudioSource) {
    if (!this.avatarAudioSources) {
      return;
    }
    const index = this.avatarAudioSources.indexOf(avatarAudioSource);
    if (index === -1) {
      this.avatarAudioSources.push(avatarAudioSource);
    }
    NAF.utils.getNetworkedEntity(avatarAudioSource.el).then(networkedEl => {
      this.networkedEl = networkedEl;
      // ToDo uuid�𐳂����ꏊ����擾
      const uuid = networkedEl.object3D.el.firstUpdateData.creator;
      const people = window.APP.people;
      if (people.length === 0) {
        const positional = window.APP.store.state.preferences.audioOutputMode !== "audio";
        updateAvatarAudioSettings(avatarAudioSource, this.audioSettings, positional);
        return;
      }
      // ToDo �e�v���C���[���Aprofile��uuid���w��
      const target = people.find(x => x.presence.metas[0].profile.uuid === uuid);
      const amIAdmin = configs.isAdmin();
      const isTargetAdmin = target.presence.metas[0].profile.isAdmin;
      const positional = (amIAdmin || !isTargetAdmin) && window.APP.store.state.preferences.audioOutputMode !== "audio";
      updateAvatarAudioSettings(avatarAudioSource, this.audioSettings, positional);
    });
  }

  updateAudioSettings(settings) {
    this.audioSettings = Object.assign({}, this.defaultSettings, settings);

    for (const mediaVideo of this.mediaVideos) {
      updateMediaAudioSettings(mediaVideo, settings);
    }

    for (const avatarAudioSource of this.avatarAudioSources) {
      updateAvatarAudioSettings(avatarAudioSource, settings);
    }
  }

  onSceneReset = () => {
    APP.sceneAudioDefaults.delete(SourceType.AVATAR_AUDIO_SOURCE);
    APP.sceneAudioDefaults.delete(SourceType.MEDIA_VIDEO);
    for (const [el, audio] of APP.audios.entries()) {
      updateAudioSettings(el, audio);
    }
  };
}
