import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ControlInstructionViewport,
  ControlInstructionDetailViewport,
  ControlInstructionViewportButton,
  ControlInstructionToolbarButton
} from "./ControlInstructionModal";
import { useMaintainScrollPosition } from "../misc/useMaintainScrollPosition";
import { useIntl } from "react-intl";

export function ControlInstructionViewportContainer({ scene, isEntered, onClose }) {
  return (
    <ControlInstructionViewport isEntered={isEntered} onClose={onClose}></ControlInstructionViewport>
  );
}

ControlInstructionViewportContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export function ControlInstructionDetailViewportContainer({ scene, onClose }) {
  return (
    <ControlInstructionDetailViewport onClose={onClose}></ControlInstructionDetailViewport>
  );
}

ControlInstructionDetailViewportContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};


export function ControlInstructionViewportButtonContainer(props) {
  return <ControlInstructionViewportButton {...props} />;
}

export function ControlInstructionToolbarButtonContainer(props) {
  return <ControlInstructionToolbarButton {...props} />;
}