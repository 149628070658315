import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { WrappedIntlProvider } from "./wrapped-intl-provider";
import { getHallId, getHallName, isInHall, getBoothList } from "../utils/livexr-utils";
import styles from "../assets/stylesheets/booth-list.scss";

import boothListGo from "../assets/images/livexrUI/Go.png"

export default class BoothList extends Component {
    static propTypes = {
        hub: PropTypes.object,
        onExpand: PropTypes.func,
        scene: PropTypes.object,
        expanded: PropTypes.bool,
    };

    

    constructor(props) {
        super(props);
        this.boothListButton = React.createRef();
        this.boothListContent = React.createRef();
        this.isContentVisible = false;
    }



    unexpand() {
        if (this.props.expanded) {
            this.props.onExpand(false, true);
        }
    }

    componentDidMount() {
        this.unexpand = this.unexpand.bind(this);
        document.querySelector(".a-canvas").addEventListener("mousedown", this.unexpand);
        // HACK: The listed-media component exists before the media-loader component does, in cases where an entity is created from a network template because of an incoming message, so don't updateMediaEntities right away.
        // Sorry in advance for the day this comment is out of date.
    }
    componentWillUnmount() {
        const canvas = document.querySelector(".a-canvas");
        if (canvas) {
            canvas.removeEventListener("mousedown", this.unexpand);
        }
    }

    componentDidUpdate() {}

    domForEntity(el, i) {
        const sceneHallId = (this.props.hub.scene? getHallId(this.props.hub.scene.scene_id): -1);

        if (!isInHall(sceneHallId, el.boothNo)) {
            return;
        }

        const spwanPointName = el.boothIsLink? el.boothSpawnName: "#" + el.boothSpawnName;
        return (
            <div>
                <div className={styles.inRoomBoothlistRow} key={"Row" + el.boothDisplayNo}>
                    <div className={styles.inRoomBoothlistBoothno}>{ el.boothDisplayNo }</div>
                    <div className={styles.inRoomBoothlistBoothname}>{ el.boothDisplayName }</div>
                    <div className={styles.inRoomBoothlistBoothspawnpoint}><a href={spwanPointName} onClick={this.unexpand}><img src={boothListGo} width="26" /></a></div>
                </div>
                <div className={styles.inRoomBoothlistLine} key={"Line" + el.boothDisplayNo}></div>
            </div>
        );
    }

    renderExpandedList() {
        const boothListJson = getBoothList();
        const sceneHallName = getHallName(this.props.hub.scene.scene_id);
        return (
            <div className={styles.inRoomBoothlistContent}>
                <div className={styles.inRoomBoothlistContentTop}>
                </div>
                <div className={styles.inRoomBoothlistContentTitle}>
                    <div className={styles.inRoomBoothlistContentHallname}>{sceneHallName}</div>
                    {/*
                    <div className={styles.inRoomBoothlistContentListtitle}>一覧リスト</div>
                    */}
                </div>
                <div className={styles.inRoomBoothlistContentHeader}>
                    <div className={styles.inRoomBoothlistBoothno}>No.</div>
                    <div className={styles.inRoomBoothlistBoothname}>名前</div>
                    <div className={styles.inRoomBoothlistBoothspawnpoint}>移動</div>
                </div>
                <div className={styles.inRoomBoothlistContentHeader}>
                    <div className={styles.inRoomBoothlistLine}></div>
                </div>
                <div className={styles.inRoomBoothlistContentMid}>
                {
                    boothListJson.map(this.domForEntity.bind(this))
                }
                </div>
                <div className={styles.inRoomBoothlistContentBottom}></div>
            </div>
        );
    }

    render() {
        const sceneHallId = (this.props.hub.scene? getHallId(this.props.hub.scene.scene_id): -1);
        const showRoomButton = (sceneHallId == -1)? false: true;
        return (
            <WrappedIntlProvider>
                <div className={styles.inRoomBoothlistCornerButton}>
                    {showRoomButton && (
                        <div
                            className={styles.inRoomBoothlistButton}
                            onClick={() => {
                                this.props.onExpand(!this.props.expanded, !AFRAME.utils.device.isMobileVR());
                            }}
                        >
                        </div>
                    )}
                    {showRoomButton && this.props.expanded && this.renderExpandedList()}
                </div>
            </WrappedIntlProvider>
        );
    }

}
