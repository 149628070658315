import React, { useRef, useState, useEffect } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Modal } from "../modal/Modal";
import { CloseButton } from "../input/CloseButton";

export function VideoModal({ destinationUrl, onClose }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("error", (event) => {
        event.preventDefault();
      });
      ref.current.play();
      
    }
  }, []);

  return (
      <video
        playsInline
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: "#000"
        }}
        disablePictureInPicture 
        controls
        controlsList="nodownload"
        preload="auto"
        onError={(err) => {
          window.dispatchEvent(new CustomEvent("videoEmbed_hide"));
          window.dispatchEvent(new CustomEvent("videoError_show"));
        }}
      >
        {destinationUrl}
        Your browser doesn't support HTML5 video tag.
      </video>
  );
}

VideoModal.propTypes = {
  destinationUrl: PropTypes.string,
  onClose: PropTypes.func
};
