import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./jumpButton.scss";
import jumpBtnImg from "../../assets/images/livexrUI/Jump_Btn.png"

export class JumpButton extends Component {
  constructor(props) {
    super(props);
  }

  onJump() {
    //console.log("JumpButton, onJump(): ");
    window.dispatchEvent(new CustomEvent("performJump"));
  }

  render() {
    return (
      <div
        className={styles.jumpButtonContainer}
        onClick={this.onJump}
      >
        <img src={jumpBtnImg} />
      </div>
    );
  }
}

