import React from "react";
import PropTypes from "prop-types";
import { LoadingScreenLayout } from "../layout/LoadingScreenLayout";
import { LoadingEventContainer } from "./LoadingEventContainer";
import { Spinner } from "../misc/Spinner";
import configs from "../../utils/configs";
import { useRandomMessageTransition } from "./useRandomMessageTransition";
export function LoadingScreen({ logoSrc, message, infoMessages }) {
  const infoMessage = useRandomMessageTransition(infoMessages);
  return (
    <LoadingScreenLayout
      logoSrc={logoSrc}
      center={
        <>
          {window.APP.rolePermission.getUiPermission("showEventOnLoadingScreen")? (
            <LoadingEventContainer />
          ): (
            <Spinner />
          )}
          <p>{message}</p>
        </>
      }
      bottom={configs.ui.showNewsOnLoadingScreen? (
        <>
          <h3>{infoMessage.heading}</h3>
          <p>{infoMessage.message}</p>
        </>
      ): null}
    />
  );
}

LoadingScreen.propTypes = {
  logoSrc: PropTypes.string,
  message: PropTypes.node,
  infoMessages: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.node.isRequired,
      message: PropTypes.node.isRequired
    })
  )
};

LoadingScreen.defaultProps = {
  infoMessages: []
};
