import React, { useRef, useState, useEffect } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../icons/Close.svg";
import styleAllowVoiceModal from "./AllowVoiceModal.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";

export function AllowVoiceModal({
  onClose,
  ...rest
}) {
  const breakpoint = useCssBreakpoints();
  
  return ( 
    <div className={styleAllowVoiceModal.allowVoiceModalContainer}>
      <div className={styleAllowVoiceModal.allowVoiceModalRelativeContainer}>
        <div className={styleAllowVoiceModal.allowVoiceModalCloseBtnContainer}>
          <div className={styleAllowVoiceModal.allowVoiceModalCloseBtn}>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <div className={styleAllowVoiceModal.allowVoiceModalContentContainer}>
          発言許可されました。<br/>
          ツールバーでマイクをオンにしてください。
        </div>
      </div>
    </div>
  )
}
AllowVoiceModal.propTypes = {
  onClose: PropTypes.func
};
