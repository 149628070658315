import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Sidebar } from "../sidebar/Sidebar";
import { ViewportList } from "../viewportlist/ViewportList";
import { CloseButton } from "../input/CloseButton";
import { ReactComponent as ChatIcon } from "../icons/Chat.svg";
import { IconButton } from "../input/IconButton";
import { ToolbarButton } from "../input/ToolbarButton";
import styles from "./WaypointSidebar.scss";
import { FormattedMessage, useIntl, defineMessages, FormattedRelativeTime } from "react-intl";
import registerGA from "../../telemetry-ga";

import iconWeb from "../../assets/images/waypointlist/icon_web.png"
import iconWaypoint from "../../assets/images/waypointlist/icon_position.png"
import iconRoom from "../../assets/images/waypointlist/icon_door.png"

import iconGo from "../../assets/images/waypointlist/move.png"
import configs from "../../utils/configs";

import { idForHubsRoomUrl } from "../../utils/media-url-utils";
import { isECRoom, createGmoLoginURL } from "../../utils/livexr-utils";

export function WaypointElement(props) {
  var icon = null;
  var target = "_self";
  var url = "";
  var ga_event = "";
  const roomName = window.APP.hub.name;
  switch (props.boothType) {
    case 1:
      icon = iconWaypoint;
      target = "_self";
      url = "#" + props.boothSpawnName;
      break;
    case 2:
      icon = iconWeb;
      target = "_target";
      url = props.boothSpawnName;
      ga_event = "External_Link";
      break;
    case 3:
      icon = iconRoom;
      target = "_self";
      url = props.boothSpawnName;
      ga_event = "Room_Link";
      break;
    case 4:
      icon = iconWeb;
      target = "_target";
      url = props.boothSpawnName;
      ga_event = "Doc_Link";
      break;
    case 5:
      icon = iconWeb;
      target = "_target";
      url = props.boothSpawnName;
      ga_event = "Video_Link";
      break;
  }
  const onclick= (eventName, eventCategory, eventLabel) => {
    if (eventName) {
      registerGA(eventName, eventCategory, eventLabel, 1);
    }
  }


  var shouldShow = true;
  if (!configs.isAdmin() && props.boothAdminOnly) {
    shouldShow = false;
  }

  /*
  if (props.boothType == 3) {
    //Xショップ部屋かどうかを判定
    const hubsRoomId = idForHubsRoomUrl(url);
    const isECRoomFlag = isECRoom(hubsRoomId)
    if (isECRoomFlag) {
      //Xショップ部屋のため、GMOMSログインURLを書き換えます
      const locationHref = createGmoLoginURL(url);
      url = locationHref;
    }
  }
  */
  
  return (
    !shouldShow? 
      <></>
    :
    <div className={styles.listTable} style={{
      "border-bottom": "2px solid" + props.borderColor
    }}>
      <ul className={styles.listRow}>
        <li className={classNames(styles.listCell, styles.waypointNo)}>{props.countIndex + 1}</li>
        <li className={classNames(styles.listCell)}>{props.boothDisplayName}</li>
        <li className={classNames(styles.listCell, styles.waypointIcon)}><a target={target} href={url} onClick={()=> {onclick(ga_event, roomName + "_" + props.boothDisplayName, url)}}><img src={iconGo} width="81" height="49" /></a></li>
      </ul>
    </div>
  );
}
export function WaypointHeaderElement(props) {
  return (
    <div className={styles.listHeaderTable} style={{
      "border": "2px solid" + props.borderColor
    }}>
      <ul className={styles.listRow}>
        <li className={classNames(styles.listCell, styles.waypointNo)}><FormattedMessage id="waypoint-header.no" defaultMessage="No." /></li>
        <li className={classNames(styles.listCell)}><FormattedMessage id="waypoint-header.name" defaultMessage="Name" /></li>
        <li className={classNames(styles.listCell, styles.waypointIcon)}><FormattedMessage id="waypoint-header.link" defaultMessage="Link" /></li>
      </ul>
    </div>
  );
}

WaypointElement.propTypes = {
  sent: PropTypes.bool,
  sender: PropTypes.string,
  timestamp: PropTypes.any,
  messages: PropTypes.array
};

export const WaypointList = forwardRef(({ children, ...rest }, ref) => (
  <div {...rest} className={styles.listTableContainer} ref={ref}>
    <ul className={classNames(styles.listRow, styles.listRowHeader)}>
      <li className={classNames(styles.listCell, styles.waypointNo)}>No</li>
      <li className={classNames(styles.listCell)}>Name</li>
      <li className={classNames(styles.listCell, styles.waypointIcon)}>Icon</li>
    </ul>
    {children}
  </div>
));

WaypointList.propTypes = {
  children: PropTypes.node
};

export const WaypointViewportList = forwardRef(({ children, ...rest }, ref) => (
  <div {...rest} className={styles.listTableContainer} ref={ref}>
    {children}
  </div>
));

WaypointViewportList.propTypes = {
  children: PropTypes.node
};

export function WaypointSidebar({ onClose, children, ...rest }) {
  return (
    <Sidebar
      title={<FormattedMessage id="waypoint-sidebar.title" defaultMessage="Waypoint" />}
      beforeTitle={<CloseButton onClick={onClose} />}
      contentClassName={styles.content}
      {...rest}
    >
      {children}
    </Sidebar>
  );
}

WaypointSidebar.propTypes = {
  onClose: PropTypes.func,
  onScrollList: PropTypes.func,
  children: PropTypes.node,
  listRef: PropTypes.func
};

export function WaypointViewport({ onClose, children, headerNode, tagNode, type, ...rest }) {
  return (
    <ViewportList
    headerNode={headerNode}
    tagNode={tagNode}
    onClose={onClose}
    type={type}
    {...rest}
    >
      {children}
    </ViewportList>
  );
}

WaypointSidebar.propTypes = {
  onClose: PropTypes.func,
  onScrollList: PropTypes.func,
  children: PropTypes.node,
  headerNode: PropTypes.node,
  tagNode: PropTypes.node,
  type: PropTypes.string,
  listRef: PropTypes.func
};

export function WaypointToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={<ChatIcon />}
      preset="accent3"
      label={<FormattedMessage id="waypoint-toolbar-button" defaultMessage="Waypoint" />}
    />
  );
}

export function WaypointViewportButton(props) {
  const {label, onClick, type} = props;
  return (
    <button
      className={type == "waypoint"? styles.waypointButton: styles.waypointDocumentButton}
      onClick={onClick}
    >
      <div className={styles.waypointButtonContent}>
        <div className={styles.waypointButtonText}>{label}</div>
      </div>
      
    </button>
    
  );
}


export const WaypointTagList = forwardRef(({ children, ...rest }, ref) => (
  <div {...rest} className={styles.listTagContainer} ref={ref}>
    {children}
  </div>
));
WaypointTagList.propTypes = {
  children: PropTypes.node
};

export function WaypointTagListElement(props) {
  return (
    <button className={styles.listTagButton} style={{
      border: "2px solid " + props.borderColor,
    }} onClick={props.onClick}>{props.title}</button>
  );
}
