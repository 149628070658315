import React from "react";
import classNames from "classnames";

import styles from "./LiveXRRegistrationPage.scss";

export const termsContentInner=(
  <div key={"term" + Math.random()} className={classNames(styles.termContainer)}>
  <div className={classNames(styles.chapterTitle)}>利用規約</div>
<div>
株式会社博報堂プロダクツ（以下「当社」という）は、META MESSE（以下「本サービス」という)を提供するにあたって、本サービスの利用に関する条件を利用規約（以下「本規約」という）として規定します。
<div className={classNames(styles.chapter)}>
<div className={classNames(styles.chapterTitle)}>第１章　総則 </div>
<p className={classNames(styles.bold)}>第1条（本規約の適用）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>本規約は、当社と本サービスの利用者（以下「利用者」という）との間に生ずる一切の関係に適用されるものとします。本規約に同意をしない限り、本サービスを利用することはできません。</li>
<li>利用者とは、本規約に同意し、第8条に定める本サービスの利用登録を完了した個人または法人をいいます。</li>
<li>未成年者が本サービスを利用する場合には、事前に親権者の同意を得るものとします。</li>
</ol>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第2条（本規約の発効）</p> <br/>
本規約は、利用者が第8条に定める本サービスの利用登録を完了した時点から、その効力が生じるものとします。 <br/>
<br/>

<br/>
<p className={classNames(styles.bold)}>第3条（本規約の変更等）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>当社は、本規約の内容を変更若しくは新たな内容を追加し、又は本規約の内容を削除すること（以下総称して「本規約の変更等」という）ができるものとします。なお、本規約の変更後、利用者が本サービスを継続して利用した時点で、変更後の規約に同意されたものとみなします。</li>
<li>当社は、本規約の他に本サービスの利用に係る個別的な規則（以下「個別規則」という）を定め、個別規則の内容を変更若しくは新たな内容を追加し、又は個別規則の内容を削除すること（以下総称して「個別規則の制定等」という）ができるものとします。個別規則はその名称の如何に関わらず、本規約の一部を構成するものとします。また、個別規則の定めと本規約の定めとが抵触する場合は、個別規則の定めが優先されるものとします。</li>
<li>本規約の変更等又は個別規則の制定等を行う場合、当社は利用者に対し、本サービスのウェブサイト（<a href="https://www.meta-messe.com/" target="_blank">https://<wbr/>www.<wbr/>meta-<wbr/>messe.com/<wbr/></a>）、電子メール又はその他当社が適当と判断した方法により、本規約の変更等又は個別規則の制定等の内容及び効力発生時期を通知するものとします。通知が利用者へ到達した日（ウェブサイトを用いて通知を行う場合には、ウェブサイトの公開日とする）又は本規約の変更等若しくは個別規則の効力発効日のいずれか遅い方の日以降に利用者が本サービスを利用した場合、法令上その効力を否定されるときを除き、利用者は変更後の本規約又は個別規則に同意したものとみなします。</li>
</ol>
</div>


<div className={classNames(styles.chapter)}>
<div className={classNames(styles.chapterTitle)}>第2章　提供 </div>
<p className={classNames(styles.bold)}>第4条（本サービスの提供）</p> <br/>
<ol className={classNames(styles.decimalList)}>
  <li>当社は、直接若しくは間接又は明示若しくは黙示を問わず、利用者に対して、本サービスに関する権利又は権原、本サービスの安全性、信頼性、正確性、完全性、有効性又は特定の目的への適合性、本サービスにセキュリティ等に関する欠陥がないこと、本サービスにエラー、バグ又は動作不良（利用者の利用環境を問わない）が無いこと、本サービスの全部又は一部の提供停止又は廃止をしないこと、本サービスに第三者の権利の侵害がないこと、本サービスの利用に際して利用者がコンピュータウイルスまたは外部からの攻撃等を受けないこと、本サービスを通じて送信または受信される情報が流出、消失又は改ざんされないこと、その他本サービスに関する一切の事項について如何なる種類の表明又は保証を行うものではありません。</li>

  <li>当社は、次の各号の何れかに該当すると判断したときは、利用者に対して事前の通知なく、本サービスの全部又は一部の変更又は提供の中断、停止若しくは終了（以下総称して「本サービスの変更等」という）を行うことができるものとします。また、利用者は、本サービスの変更等の内容又は理由に関して、何らの照会、異議申立又は請求を行うことができないものとします。
    <ol  className={classNames(styles.bracketList)}>
      <li>定期的または緊急に、本システム提供のためのコンピュータシステムの保守・点検を行う場合</li>
      <li>火災・停電、天災地変等の非常事態により本サービスの運営が不能となった場合</li>
      <li>戦争、内乱、暴動、騒擾、労働争議等により、本システムの運営が不能となった場合</li>
      <li>本サービス提供のためのコンピュータシステムの不良及び第三者からの不正アクセス、コンピュータウイルスの感染等により本サービスを提供できない場合</li>
      <li>法律、法令等に基づく措置により本システムが提供できない場合</li>
      <li>当社が合理的な範囲で、事前に周知していた場合</li>
      <li>その他、何らかの事情により当社が止むを得ないと判断した場合</li>
    </ol>
  </li>

  <li>当社は、前項に基づく本サービスの変更等より生じた利用者又は第三者の損害について、何らの責任を負うものではないものとします。</li>
  <li>本条の定めは、当社と利用者との間で本規約の適用がなされなくなった後も、なお効力を有するものとします。</li>
</ol>

<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第5条（免責事項）</p> <br/>
<ol className={classNames(styles.decimalList)}>
  <li>当社は、本サービス及び本サービスにおいて提供するコンテンツに関して、信頼性、正確性、的確性、適法性、安全性、完全性、権原及び非侵害性、特定の利用目的への適合性、本サービスが停止しないこと、動作不良がないこと、常に本サービスを利用できること、本サービスが終了しないこと、セキュリティに関する欠陥がないこと、エラーおよびバグ、第三者の権利侵害がないこと等、その品質および内容、提供状況に関して、何ら保証しません。</li>
  <li>当社は、利用者がコンピュータウイルス又は外部からの攻撃等を受けないことを何ら保証しません。</li>
  <li>当社は、本サービスを通じて送信または受信される情報が流出、消失または改ざんされないことを何ら保証しません。</li>
</ol>
</div>

<div className={classNames(styles.chapter)}>
<div className={classNames(styles.chapterTitle)}>第4章　利用 </div>
<p className={classNames(styles.bold)}>第6条（本サービスの利用）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>利用者は、当社の定める本規約、個別規則、その他の本サービスの利用に関する一切の定め、及びイベント主催者（以下「主催者」という）の定める個別規則に従って、本サービスを利用するものとします。</li>
<li>利用者は、自らの責任と負担において、本サービスの機能を用いての他の利用者とのコミュニケーション、その他本サービスの利用及びこれに関連付随する一切の行為を行い、その結果について一切の責任を負うものとします。当社は、利用者による本サービスの利用又はその結果に起因する損害又は問題について、何らの責任を負うものではないものとします。</li>
<li>当社は、本サービスの利用に起因する利用者と第三者の間又は第三者間での紛争等について何らの責任を負うものではないものとします。利用者が本サービスの利用に起因して第三者に対する損害を生じさせた場合又は第三者からの訴訟上若しくは訴訟外での請求がなされた場合、自らの責任と費用をもって処理し、解決をしなければならないものとします。利用者が本サービスの利用に起因して第三者から損害を被った場合又は第三者に対して訴訟上若しくは訴訟外での請求を行う場合においても同様とします。</li>
<li>利用者の本サービスの利用に起因して当社が第三者から訴訟上又は訴訟外での請求がなされる等の当社と第三者との間に紛争が生じた場合、利用者は、自らの責任と費用により当該紛争を解決し、当社をして責任を免れせしめるものとします。また、当社に損害が生じたときには、利用者は当社の損害（第三者との間での紛争に関して要した合理的な金額の弁護士費用を含む）を賠償するものとします。</li>
<li>本サービスで表示する日時は、特段の定めのない限り、全て日本標準時とします。</li>
</ol>
<br/>
<br/>
<p className={classNames(styles.bold)}>第7条（利用環境の推奨）</p> <br/>
当社は、本サービスの利用に際して、ハードウェア、OSのスペック等を含む利用者側での利用環境の推奨を行うことがあります。当社が推奨する環境以外で本サービスを利用した場合には、本サービスの機能の全部又は一部について、使用できない、動作に不都合が生じる又は通常予定される効用が実現できない可能性があります。但し、利用環境の推奨は、本システムの機能、動作その他について、当社が何らの表明又は保証を行うものではありません。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第8条（利用登録）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>利用者は、本サービスの利用登録（以下単に「利用登録」という）を完了した後に利用できるものとします。利用者が、本規約に同意の上、当社及び主催者の定める方法でその申請を行うことによって、利用登録を完了するものとします。</li>
<li>
利用登録に関して、次の各号に該当する事由があると当社が判断した場合、当社は、主催者の定める規則その他にかかわらず、当社の判断により、利用登録の変更又は取消ができるものとします。また、利用者は、一切の事項に関して、何らの照会、異議申立又は請求を行うことができないものとします。
<ol  className={classNames(styles.bracketList)}>
  <li>利用登録に際して虚偽の申告があった場合</li>
  <li>過去に本規約の違反等がある場合</li>
  <li>その他当社が利用登録を承認することを相当でないと判断した場合</li>
</ol>
</li>
</ol>
<br/>
<br/>
<p className={classNames(styles.bold)}>第9条（ID及びパスワードの管理）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>利用者は、自己の責任において、保有するMETA MESSE-ID（以下「MM-ID」という）及びパスワードを適切に管理するものとします。</li>
<li>利用者は、如何なる場合にも、MM-ID及びパスワードを第三者に譲渡若しくは貸与し、又は第三者と共用することはできません。</li>
<li>当社は、MM-IDとパスワードの組み合わせが、利用登録にあたって利用者により登録された情報、又は利用登録の完了後に当社が定める方法にて利用者により変更された情報と一致してログインされた場合、そのMM-IDを保有している利用者自身による利用とみなします。</li>
<li>当社は、MM-ID及びパスワードが第三者によって使用されたことによって生じた損害について、何らの責任を負うものではないものとします。</li>
<li>前項の定めは、当社と利用者との間で本規約の適用がなされなくなった後も、なお効力を有するものとします。</li>
</ol>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第10条（禁止行為）</p><br/>
<ol className={classNames(styles.decimalList)}>
<li>利用者は、本サービスの利用にあたり、次の各号に該当する又は該当する恐れのある行為をしてはなりません。</li>
<ol  className={classNames(styles.bracketList)}>
<li>法令又は公序良俗に反する行為、その他インターネット上で一般的に遵守されている規範（社会的又は事実的な規範を含む）に反する行為</li>
<li>当社又は第三者に不利益、迷惑、不快感又は損害を与える行為</li>
<li>有償又は無償を問わず、当社の許可なく本サービスを第三者へ再提供する行為</li>
<li>当社若しくは第三者を誹謗中傷、侮辱若しくは差別し、名誉、信用、プライバシー等の人格的利益を毀損する行為、人権を侵害する行為又はこれらを推奨、勧誘、協力、助長若しくは教唆する行為</li>
<li>当社又は第三者の知的財産権等を侵害する行為</li>
<li>殺害、テロ、虐待、傷害、暴行、脅迫、窃盗、強盗、淫行その他の犯罪行為若しくは自殺、自傷、非行、家出等の不適切な行為又はこれらを推奨、勧誘、協力、助長若しくは教唆する行為</li>
<li>法人若しくは個人を問わず自らを偽る行為、虚偽若しくは誤解を生む情報を提供する行為、特定の事業者等の商品・サービス等を優良と誤認させようとする行為又は特定の事業者等の商品・サービス等の信頼を低下させる目的で中傷若しくは批判する行為</li>
<li>公職選挙法（昭和25年法律第100号）に違反する行為</li>
<li>反社会的勢力（暴力団、暴力団関係企業、総会屋、社会運動標ぼうゴロその他暴力、威力、詐欺的手法を駆使して経済的利益を追求する集団若しくは個人又はこれらに準じる者をいう）、犯罪組織、違法な賭博・ギャンブル、違法薬物（脱法薬物、薬物等の濫用・不適切な利用若しくはこれらに準じるものを含む）、無限連鎖講（ねずみ講）、ネットワークビジネス・マルチ商法、違法ビジネス、詐欺、公文書偽造、火器・けん銃・爆弾を含む違法な武器・兵器・爆発物・危険物等の製造・取引、その他違法な物品・情報等の作成・取引等に関与し、又はこれらを推奨・勧誘・利益供与・協力・助長・教唆する行為</li>
<li>異性若しくは同性との交際、出会い若しくは性交渉を目的とした行為、不健全な目的による個人情報の聞き出し、送信若しくは受信、性風俗、売買春、わいせつ、アダルト・ポルノ関連のコンテンツ、児童ポルノ、児童虐待、動物虐待、残虐なコンテンツ等に該当し、又はこれらについて推奨、勧誘、利益供与、協力、助長若しくは教唆する行為</li>
<li>当社若しくは第三者の運用するコンピュータ、ウェブサイト、電気通信設備等に不正にアクセスする行為、クラッキング行為、アタック行為、通信妨害行為、通信傍受行為、又は当社若しくは第三者の運用するコンピュータ、ウェブサイト、電気通信設備等に支障を与える方法又は態様において本サービスを利用する行為、若しくはそれらの行為を促進する情報を掲載する等の行為</li>
<li>MM-ID若しくはパスワードを不正に使用若しくは盗用する行為又は他者になりすまして本サービスを利用する行為</li>
<li>本サービスが予定している利用態様を超えて、本サービスからアクセス可能な第三者の情報を収集、複製、改竄し、又は消去する行為</li>
<li>コンピュータウイルス、ファイル共有ソフト等第三者の業務を妨害し、又は損害を与えるコンピュータプログラムを提供する行為</li>
<li>検索エンジン・検索ツール等の表示結果における順位の操作を目的として行う行為（ソフトウェア・ツール等を利用するか否かにかかわらず、複数のアカウントを生成し、同一若しくは類似の記事を複数回にわたり投稿等する行為、又は生成されたアカウントを利用して、コンテンツを投稿等し、他のウェブサイト、他のアカウント、他の投稿コンテンツに対してリンクを貼る等により誘導する行為等を含み、これらに限らない）</li>
<li>受信者が嫌悪感を抱く電子メール等のメッセージ・コンテンツ（特定電子メール、迷惑メールを含み、これらに限定されない）を送信・投稿等する行為</li>
<li>本人の明確な同意なく、不正に個人情報若しくはプライバシー情報を調査、収集、利用、開示、提供する行為</li>
<li>本サービスの運営に過度な負担を与える行為・妨害する行為</li>
<li>本サービスに含まれるプログラム・アプリケーションを分解、逆アセンブル、逆コンパイル、リバースエンジニアリングする行為、又はその他の方法でソースコードを解読する行為</li>
<li>本サービスの利用目的と異なる目的で本サービスを利用する行為、又は本コンテンツを、本サービスが予定している利用態様を超えて利用（複製、送信、転載、改変などの行為を含む）する行為</li>
<li>その他本サービスの内容に鑑みて、当社が不適当と判断する行為</li>
</ol>
</ol>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第11条（権利義務の譲渡の禁止）</p> <br/>
利用者は、当社からの事前の承諾なく、本規約に基づく一切の契約上の関係を第三者に譲渡し、又は担保に供することはできません。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第12条（反社会的勢力の排除）</p> <br/>
利用者は、自身が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力集団、その他の反社会的勢力（以下｢反社会的勢力｣という）に所属又は該当せず、かつ、反社会的勢力と関与していないことを表明し、将来にわたっても所属若しくは該当し、又は関与しないことを確約するものとします。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第13条（利用制限）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>
利用者が次の各号の何れかに該当する場合、主催者の定める規則その他にかかわらず、当社は利用者に対して事前の通知なく、本サービスの全部若しくは一部を停止することができます。
<ol  className={classNames(styles.bracketList)}>
  <li>利用者が本規約に定める債務の履行を怠ったとき</li>
  <li>利用者が本規約に定める義務の履行を怠ったとき</li>
  <li>利用者が申込書に虚偽の申告をしたことが明らかになったとき</li>
  <li>利用者が、利用契約に基づく利用料金を、支払期限が経過しても支払わないとき</li>
  <li>著しくデータ量が増加し、他の利用者に迷惑がかかると判断したとき</li>
  <li>利用者のアカウントが外部からの攻撃等で本サービスの運用に支障があると判断されるとき</li>
  <li>その他利用者へのサービス提供が相当でない場合</li>
</ol>
</li>

<li>本サービスの利用を制限する場合は、原則として利用者に対して事前に連絡しますが、緊急の場合には事前連絡をしないことがあります。</li>
<li>第1項に定める理由により、本サービスの利用を制限した場合、契約の解除がない限り所定の利用料金を申し受けます。</li>
<li>第1項に起因して生じた利用者又は第三者の損害について、何らの責任を負うものではないものとします。</li>
</ol>
</div>

<div className={classNames(styles.chapter)}>
<div className={classNames(styles.chapterTitle)}>附則 </div>
<p className={classNames(styles.bold)}>第14条（個人情報の取扱い）</p> <br/>
当社は、本サービスにおいて取得した利用者の個人情報及び個人関連情報（以下「個人情報」という）を以下の目的で利用します。 <br/>
<br/>
<ol className={classNames(styles.decimalList)}>
<li>ご登録いただくメールアドレスを以下の目的で利用します。</li>
<ol  className={classNames(styles.bracketList)}>
  <li>本サービスの利用登録</li>
  <li>本サービスの提供、及びサービス提供に関連するご連絡、お問い合わせ対応</li>
  <li>本サービスに付随する新商品・サービスに関する情報のお知らせ</li>
</ol>

<li>
  本サービスでは、IPアドレス、端末ID、ユーザーエージェント、リファラ、クッキー等を自動取得し、利用者の閲覧履歴・行動履歴等の情報を解析します。解析した情報とご登録いただいたメールアドレスを照合し、以下の目的で利用します。
  <ol className={classNames(styles.bracketList)}>
    <li>本サービスの改善や新サービスの開発等に役立てるための統計データの作成</li>
    <li>主催者に対する本サービスの利用状況等の分析結果の報告</li>
  </ol>
</li>

<li>本サービスの利用登録時に取得した個人情報を主催者に提供することがあります。なお、提供する個人情報は主催者が別で定めるプライバシーポリシーのもと取り扱われます。上記以外に利用者の個人情報を本人の同意なしに第三者（業務委託を除く）に開示・提供することはありません。但し、法令等により開示を求められた場合を除きます。</li>
<li>その他、当社の個人情報に関する安全管理策や開示請求等の手続きについては、当社の個人情報保護方針（<a href="https://www.h-products.co.jp/privacy.html" target="_blank">https://<wbr/>www.h-products.<wbr/>co.jp/<wbr/>privacy.<wbr/>html</a>）に定める「個人情報に関する公表事項」をご確認ください。</li>
</ol>
<br/>
<br/>
<p className={classNames(styles.bold)}>第15条（クッキーの取扱い）</p> <br/>
本サービスでは、本サービスの利便性向上、及びウェブサイトの改善や品質向上のためにクッキー及びウェブビーコン技術を利用しています。「クッキー」とは、ウェブサイトを利用した際、ブラウザとウェブサーバーとの間で送受信した利用履歴等を利用者のコンピュータに保存し、本サービスをより便利に利用するためのものです。クッキー自体には、氏名、住所、電話番号等の個人情報は含みません。 <br/>
<br/>
取得したクッキー情報は、本サービスを開発する株式会社プレミアムアーツのウェブサーバーから解析ツールベンダー（Googleアナリティクス等）に送信・蓄積され、解析ツールベンダーが提供するASPサービスを利用して、蓄積されたデータから当社ウェブサイトの閲覧状況（閲覧数、滞在時間等）の統計データを入手します。 <br/>
<br/>
なお、Googleアナリティクスで取得するデータは、Google社のプライバシーポリシーに基づいて管理されます。Googleアナリティクスの利用規約、及びGoogle社のプライバシーポリシーについては下記をご確認ください。 <br/>
<br/>
<ul className={classNames(styles.discList)}>
<li>Googleアナリティクス利用規約（<a href="https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/<wbr/>analytics/<wbr/>terms/<wbr/>jp.html</a>）</li>
<li>Googleプライバシーポリシー（<a href="https://policies.google.com/privacy?hl=ja" target="_blank">https://policies.google.com/<wbr/>privacy?hl=ja</a>）</li>
</ul>
<br/>
<br/>
また、ご自身のブラウザでクッキーの機能を無効にするように設定することができますが、本サービスが正しく作動しない場合があります。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第16条（問い合わせ）</p> <br/>
利用者は、当社へのお問い合わせを電子メールにて行うものとします。なお、お問い合わせ先のメールアドレス宛先は（info@meta-messe.com）とします。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第17条（通知）</p> <br/>
当社からの通知は、本サービスのウェブサイト（<a href="https://www.meta-messe.com/" target="_blank">https://<wbr/>www.<wbr/>meta-<wbr/>messe.<wbr/>com/</a>）、電子メール又はその他当社が適当と判断した方法により行うものとします。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第18条（分離可能性）</p> <br/>
本規約の一部が消費者契約法その他の法令等により無効となった場合も、無効とされた一部を除く本規約のその他は、継続して完全に効力を有するものとします。 <br/>
<br/>
<br/>
<br/>
<p className={classNames(styles.bold)}>第19条（準拠法及び管轄裁判所）</p> <br/>
<ol className={classNames(styles.decimalList)}>
<li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
<li>本サービスに関して紛争が生じた場合、当社の本店所在地を管轄する地方裁判所又は簡易裁判所を第一審の専属的合意管轄裁判所とします。</li>
<li>本条の定めは、当社と利用者との間で本規約の適用がなされなくなった後も、なお効力を有するものとします。</li>
</ol>
</div>

令和4年6月30日制定 

</div>

  </div>
);