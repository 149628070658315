import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Marquee from "react-fast-marquee";
import eventDataJson from "../../assets/json/event_marquee.json"
import styles from "./EventMarquee.scss";

export class EventMarquee extends Component {
  state = {
    eventData: {}
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      eventData: eventDataJson.eventData.filter((data) => {
        const dataStartDate = new Date(data.startDate);
        const dataEndDate = new Date(data.endDate);
        const currentDate = new Date();
        if (currentDate >= dataStartDate && currentDate <= dataEndDate) {
          return true;
        } else {
          return false;
        }
      })
    })
  }

  render() {
    const isMobile = AFRAME.utils.device.isMobile();

    if (this.state.eventData && this.state.eventData.length > 0) {
      return (
        <Marquee
          className={isMobile? styles.MarqueeContatinerSP: styles.MarqueeContatiner}
          gradient={false}
          speed={50}
          pauseOnHover={true}
          play={true}
          delay={1}
        >
          {this.state.eventData.map((record) => {
            return record.url == ""? (
              <div className={styles.MarqueeContent}>{record.content}</div>
            ):(
              <div className={styles.MarqueeContent}><a href={record.url} target="_blank">{record.content}</a></div>
            )
          })}
        </Marquee>
      );
    }
    return (
      <></>
    );
  }
}