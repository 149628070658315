import React from "react";
import boothListJson from "../assets/json/booth_list.json";
import hallSceneListJson from "../assets/json/hall_scene_list.json";
import waypointListJson from "../assets/json/waypoint_list.json"
import videoEmbedList from "../assets/json/video_embed_list.json";
import iframeList from "../assets/json/iframe_list.json";
import archiveList from "../assets/json/archive_list.json";
import pliveRoomList from "../assets/json/plive_room_list.json";
import configs from "./configs";

export const getHallSceneId = (sceneId) => {
  const hallSceneList = getHallSceneList();

  for (let i = 0; i < hallSceneList.length; i++) {
    if (sceneId == hallSceneList[i].mobile || sceneId == hallSceneList[i].laptop) {
      return {mobile: hallSceneList[i].mobile, laptop: hallSceneList[i].laptop};
    }
  }
  return {mobile: "", laptop: ""};
};

export const getHallId = (sceneId) => {
  const hallSceneList = getHallSceneList();

  for (let i = 0; i < hallSceneList.length; i++) {
    if (sceneId == hallSceneList[i].mobile || sceneId == hallSceneList[i].laptop) {
      return hallSceneList[i].hallId;
    }
  }
  return -1;
};

export const getHallName = (sceneId) => {
  const hallSceneList = getHallSceneList();

  for (let i = 0; i < hallSceneList.length; i++) {
    if (sceneId == hallSceneList[i].mobile || sceneId == hallSceneList[i].laptop) {
      return hallSceneList[i].hallName;
    }
  }
  return "";
};

export const isInHall = (hallId, boothId) => {
  return (hallId == boothId);
};

export const getBoothList = () => {
  return boothListJson.boothlist;
};

export const getHallSceneList = () => {
  return hallSceneListJson.sceneList;
};

export const getWaypointList = (sceneId) => {
  return waypointListJson[sceneId]? waypointListJson[sceneId]: [];
};

export const hasWaypointTargetGroupList = (sceneId, targetGroupId) => {
  return waypointListJson[sceneId]? waypointListJson[sceneId].filter(element => {
    if (element.boothType == targetGroupId) {
      return true;
    }
    return false;
  }).length: 0;
};

export function getObjectNameGA(fullname){
  const words = fullname.split('##', 2);
  if (words.length > 1) {
      return words[1];
  }

  return words[0];
}

export const getExtraSetting = () => {
  var roomExSetting = {};
  try {
    if (window.APP.hub.description != null) {
      roomExSetting = JSON.parse(window.APP.hub.description);
    }
  } catch {

  }

  return roomExSetting;
};

export function getObjectNameWithoutGA(fullname){
  const words = fullname.split('##', 2);
  return words[0];
}

export function processShowIframe(objName){
  const nameWithoutGA = getObjectNameWithoutGA(objName);
  const nameSplit = nameWithoutGA.split("%%ID-");
  const specifyId = nameSplit? nameSplit[1]: "";

  if (iframeList[specifyId]) {
    const data = iframeList[specifyId];
    window.dispatchEvent(new CustomEvent("iframe_show", {
      "detail": {
        src: data
      }
    }));
    return {src: data};
  }
  return null;
}

export function getIframeList(specifyId) {
  const data = iframeList[specifyId];
  if (iframeList[specifyId]) {
    return data;
  }
  return null;
}

export function processShowVideoEmbed(objName){
  const nameWithoutGA = getObjectNameWithoutGA(objName);
  const nameSplit = nameWithoutGA.split("%%ID-");
  const specifyId = nameSplit? nameSplit[1]: "";

  if (videoEmbedList[specifyId]) {
    const data = videoEmbedList[specifyId];
    let embedSrc = null;
    if (data.type == "Vimeo") {
      embedSrc = getVimeoEmbed(data.videoId);
    } else if (data.type == "YouTube") {
      embedSrc = getYoutubeEmbed(data.videoId);
    }
    if (embedSrc) {
      window.dispatchEvent(new CustomEvent("vimeoEmbed_show", {
        "detail": {
          src: embedSrc
        }
      }));
    }
    return {id: data};
  }
  return null;
}

export function processShowArchiveVideo(objName){
  const nameWithoutGA = getObjectNameWithoutGA(objName);
  const nameSplit = nameWithoutGA.split("%%ID-");
  const specifyId = nameSplit? nameSplit[1]: "";

  if (archiveList[specifyId]) {
    const data = archiveList[specifyId];
    const embedSrc = getVideoEmbed(data);

    if (embedSrc) {
      window.dispatchEvent(new CustomEvent("videoEmbed_show", {
        "detail": {
          src: embedSrc
        }
      }));
    }
    return {src: data};
  }
  return null;
}

function getVimeoEmbed(id) {
  const link = "https://player.vimeo.com/video/" + id + "?title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed";
  // 正しい例。大文字で始まる変数は JSX の型に指定できます。
  return <iframe src={link} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen frameBorder="0" style={{
    position:"absolute",
    top:0,
    left:0,
    width:"100%",
    height:"100%"
  }}></iframe>;
}

function getYoutubeEmbed(id) {
  const link = "https://www.youtube.com/embed/" + id;
  // 正しい例。大文字で始まる変数は JSX の型に指定できます。
  return <iframe src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
    position:"absolute",
    top:0,
    left:0,
    width:"100%",
    height:"100%"
  }}></iframe>;
}

function getVideoEmbed(id) {
  // 正しい例。大文字で始まる変数は JSX の型に指定できます。
  return <source src={id} type='video/mp4' />;
}

export function checkIsOpenRoom(hubId) {
  const roomList = [
    "6dtf9PQ",
    "Vx9Dedb"
  ];
  if (roomList.includes(hubId)) {
    return true;
  }
  return false;
}

export function checkIsDemoRoom(hubId) {
  const roomList = [
    "JWsXJf3",
    "6dtf9PQ",
    "ujcZsFi",
    "oqBEADD",
    "72ADCXK",
    "tLRmKrk",
    "QSmiDek",
    "eSQuwNh",
    "3RyDRcP",
    "FEg35eq",
    "UmJ65xc",
    "j5MD462",
    "cY8u3nc",
    "AN6cmUX",
    "whZgNXU",
    "9cj9f8E",
    "iPdHaVB",
    "bzN8xxE",
    "ZF3M54k",
    "xM2caVU",
    "SohYTn6",
    "ex3UABY",
    "bFKLhEh",
    "33yNGkW",
    "TU5Np8z",
    "hyaXYpF",
    "n3vzbLM",
    "XQd4qP3",
    "4dm4cNd",
    "xy86Lt7"
  ];
  if (roomList.includes(hubId)) {
    return true;
  }
  return false;
}

export const getPliveRoomList = () => {
  return pliveRoomList;
};

export const checkIsPliveRoom = (hubId) => {
  var temp = pliveRoomList.roomList.find((data) => {return data.roomId === hubId});
  console.log("checkIsPliveRoom: ", !!temp);
  return !!temp;
  if (pliveRoomList.roomList.map((data) => {return data.roomId === hubId})[0]) {
    return true;
  }
  return false;
};

export const getPliveRoomData = (hubId) => {
  return pliveRoomList.roomList.filter((data) => {return data.roomId === hubId})[0];
  if (pliveRoomList.roomList.map((data) => {return data.roomId === hubId})) {
    return (() => {return data});
  }
  return null;
};

export const checkIsValidReferrer = (url) => {
  if (url == null) {
    console.log("checkIsValidReferrer: referrer is null");
    return false;
  }
  var temp = configs.url.referrerBase.find((data) => {return url.includes(data)});
  console.log("checkIsValidReferrer: ", !!temp);
  return !!temp;
  if (pliveRoomList.roomList.map((data) => {return data.roomId === hubId})[0]) {
    return true;
  }
  return false;
};