import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope,*/ faUser, faLock, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope/*, faUser, faLock, faArrowRight*/ } from '@fortawesome/free-regular-svg-icons'

import { Auth } from 'aws-amplify';
import styles from "./LiveXRLoginPage.scss";
import logo from "../../assets/images/logo/logo.png";


LiveXRLoginPage.propTypes = {
  loginSuccessCallback: PropTypes.func,
  verifyNeedCallback: PropTypes.func,
  onClickReg: PropTypes.func,
  onClickForgetPassword: PropTypes.func
};

export function LiveXRLoginPage({loginSuccessCallback, verifyNeedCallback, onClickReg, onClickForgetPassword}) {
  const [loginErrorState, setLoginErrorState] = useState(false)
  const inputRef_userName = useRef(null);
  const inputRef_password = useRef(null);

  const signIn = async () => {
    try {
      if (!checkAllValidate()) {
        return;
      }
      
      await Auth.signIn(inputRef_userName.current.value, inputRef_password.current.value).then((res) => {
        loginSuccessCallback();
      });
    } catch (error) {
      switch (error.code) {
        case "UserNotConfirmedException":
          verifyNeedCallback();
          break;
        default:
          setLoginErrorState(true);
          break;
      }
    }
  }

  const signUp = async () => {
    onClickReg();
  }

  const forgetPassword = () => {
    onClickForgetPassword();
  }

  const checkValidate = (e) => {
    checkElemValidate(e.target);
  }

  const errMessage = {
    "email": {
      "typeMismatch": "メールアドレスの入力形式が正しくありません。",
      "valueMissing": "メールアドレスを入力してください。"
    },
    "password": {
      "typeMismatch": "パスワードは小文字（a-z）,大文字（A-Z）,特殊文字（例：!@#$%^&*）をそれぞれ含む8桁以上でなければなりません。",
      "valueMissing": "パスワードを入力してください。"
    }
  }

  const checkElemValidate = (e) => {
    var isValid = true;
    e.classList.remove(styles.inputError);  
    
    var errElem = document.querySelector("[name=\"err_"+e.getAttribute("validatorname")+"\"]");
    if (e.validity.typeMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["typeMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["typeMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);
    }
    else if (e.validity.valueMissing) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["valueMissing"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["valueMissing"]);
      isValid = false;
      e.classList.add(styles.inputError); 
    }
    else {
      errElem.innerHTML = "";
      e.setCustomValidity("");
    }
    
    return isValid;
  }

  const checkAllValidate = (e) => {
    var isValid = true;
    document.querySelectorAll("input").forEach((elem) => {
      isValid = checkElemValidate(elem) && isValid;
    });
    return isValid;
  }

  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.logo)}><img src={logo} /></div>
      <div className={classNames(styles.login)}>
        <div className={classNames(styles.title)}>
          ログイン  
        </div>
        <div className={classNames(styles.formDiv)}>
          <form className={classNames(styles.form)} noValidate>
            <div className={classNames(styles.credentialContainer)}>
              
              <div className={classNames(styles.userDetailInputBox)}>  
                <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faEnvelope} />
                <input validatorname="email" onChange={checkValidate} ref={inputRef_userName} type="email" placeholder="メールアドレス" id="email" className={classNames(styles.inputField, styles.name)} required />
              </div>
              <div name="err_email" className={classNames(styles.errorMessageForInput)}></div>
            </div>
            <div className={classNames(styles.credentialContainer)}>
              <div className={classNames(styles.userDetailInputBox)} >
                <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faLock} inverse />
                <input validatorname="password" onChange={checkValidate} ref={inputRef_password} type="password" placeholder="パスワード" id="password" className={classNames(styles.inputField, styles.password, )} required />
              </div>
              <div name="err_password" className={classNames(styles.errorMessageForInput)}></div>
            </div>
          </form>
          <div className={classNames(styles.errorMessage)}>{loginErrorState? "メールアドレスまたはパスワードが間違っております。": ""}</div>
          <div className={classNames(styles.submitButtonDiv)}>
            <button type="button" className={classNames(styles.loginButton)} onClick={signIn} >ログイン</button>
          </div>
          <div className={classNames(styles.forgot)}>
            <p onClick={forgetPassword}>
              <span className={classNames(styles.forgotP)}>パスワードをお忘れの場合はこちら</span>
            </p>
          </div>
        
        </div>
      </div>
      <div className={classNames(styles.regButtonDiv)}>
        <button type="submit" className={classNames(styles.regButton)} onClick={signUp}>新規登録の方はこちら</button>
      </div>
      <div className={classNames(styles.height20)}></div>
    </div>
  )
}
