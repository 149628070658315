import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "../react-components/misc/Spinner";
import configs from "../utils/configs";

export function showBlackScreen() {
  var blackScreen = document.getElementById("black-screen");
  // var blackScreen = document.createElement("div");
  blackScreen.style.backgroundColor = "black";
  blackScreen.style.width = "100%";
  blackScreen.style.height = "100%";
  blackScreen.style.zIndex = "100";
  blackScreen.style.position = "absolute";
  blackScreen.id = "black-screen";
  blackScreen.className = "black-screen";
  //console.log("black screen");

  if (blackScreen && window.APP.rolePermission.getUiPermission("showLoadingOnBlackscreen")) {
    ReactDOM.render(<BlackScreenLoading />, blackScreen);
  }
}

export function hideBlackScreen() {
  var blackScreen = document.getElementById("black-screen");
  blackScreen.parentNode.removeChild(blackScreen);
}


class BlackScreenLoading extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Spinner />
      </div>
    );
  }
}