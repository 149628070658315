import configs from "./utils/configs";

window.dataLayer = window.dataLayer || [];

function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

export default function registerEvent(eventName, eventCategory, eventLabel){
  const gaTrackingId = configs.GA_TRACKING_ID;

  if (gaTrackingId) {
    console.log("Tracking: Google Analytics ID: " + gaTrackingId);
    console.log("URL trancked", eventCategory);

    gtag('config', configs.GA_TRACKING_ID, {send_page_view: false});
    gtag('event', eventName, {'event_category': eventCategory, 'event_label': eventLabel});
  }
}