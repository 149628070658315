import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope,*/ faUser, faLock, faArrowRight, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope/*, faUser, faLock, faArrowRight*/ } from '@fortawesome/free-regular-svg-icons'

import { Auth } from 'aws-amplify';
import styles from "./LiveXRForgotPasswordSubmitPage.scss";
import logo from "../../assets/images/logo/logo.png";


LiveXRForgotPasswordSubmitPage.propTypes = {
  forgetPasswordSubmitSuccessCallback: PropTypes.func,
  onClickLogin: PropTypes.func
};

export function LiveXRForgotPasswordSubmitPage({forgetPasswordSubmitSuccessCallback, onClickLogin}) {
  const [forgetPasswordErrorState, setForgotPasswordErrorState] = useState(false);
  const [forgetPasswordSuccessState, setForgotPasswordSuccessState] = useState(false);
  const inputRef_email = useRef(null);
  const inputRef_password = useRef(null);
  const inputRef_code = useRef(null);
  const inputRef_confirmpassword = useRef(null);

  const pwPattern = "^(?=.*[a-z])(?=.*\\d)[A-Za-z\\d!\"#$%&'()=~|\\-^\\\\`{+*}<>?_@\\[;:\\],.\\/ ]{8,}$";

  const forgetPassword = async () => {
    if (!checkAllValidate()) {
      return;
    }
    
    // Send confirmation code to user's email
    await Auth.forgotPasswordSubmit(inputRef_email.current.value, inputRef_code.current.value, inputRef_password.current.value).then((res) => {
      setForgotPasswordSuccessState(true);
      //forgetPasswordSubmitSuccessCallback();
    }).catch((error) => {
      setForgotPasswordSuccessState(false);
      setForgotPasswordErrorState(true);
    });
  }

  const signIn = () => {
    onClickLogin();
  }

  const checkValidate = (e) => {
    checkElemValidate(e.target);
  }

  const errMessage = {
    "email": {
      "typeMismatch": "メールアドレスの入力形式が正しくありません。",
      "valueMissing": "登録したメールアドレスを入力してください。"
    },
    "password": {
      "valueMissing": "パスワードを入力してください。",
      "patternMismatch": "パスワードは半角英字と数字をどちらも含む8文字以上にする必要があります。"
    },
    "confirmpassword": {
      "valueMissing": "パスワードを入力してください。",
      "patternMismatch": "パスワードは半角英字と数字をどちらも含む8文字以上にする必要があります。",
      "customError": "パスワードが一致していません。"
    },
    "code": {
      "valueMissing": "認証コードを入力してください。"
    },
  }

  const checkElemValidate = (e) => {
    var isValid = true;
    e.classList.remove(styles.inputError);  
    
    var errElem = document.querySelector("[name=\"err_"+e.getAttribute("validatorname")+"\"]");
    if (e.validity.typeMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["typeMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["typeMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);
    }
    else if (e.validity.valueMissing) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["valueMissing"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["valueMissing"]);
      isValid = false;
      e.classList.add(styles.inputError); 
    } else if(e.validity.patternMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["patternMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["patternMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);           // 2022.07/04 追加分(Tam)  
    }
    else {
      errElem.innerHTML = "";
      e.setCustomValidity("");
    }
    
    return isValid;
  }

  const checkAllValidate = (e) => {
    var isValid = true;
    document.querySelectorAll("input").forEach((elem) => {
      isValid = checkElemValidate(elem) && isValid;
    });

    const passwordElem = document.querySelector("[validatorname=\"password\"]");
    const passwordComfirmElem = document.querySelector("[validatorname=\"confirmpassword\"]");

    if (passwordElem && passwordComfirmElem && passwordElem.value != passwordComfirmElem.value) {
      passwordComfirmElem.innerHTML = errMessage["confirmpassword"]["customError"];
      passwordComfirmElem.setCustomValidity(errMessage["confirmpassword"]["customError"]);
      isValid = false;
    }
    return isValid;
  }

  const inputForm = (
    <div className={classNames(styles.formDiv)}>
      <form className={classNames(styles.form)} noValidate>
        
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faUserCheck} />
            <input validatorname="code" onChange={checkValidate} ref={inputRef_code} type="text" placeholder="認証コード" className={classNames(styles.inputField, styles.name)} required />
          </div>
          <div name="err_code" className={classNames(styles.errorMessageForInput)}></div>
        </div>
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faEnvelope} />
            <input validatorname="email" onChange={checkValidate} ref={inputRef_email} type="email" placeholder="メールアドレス" className={classNames(styles.inputField, styles.name)} required />
          </div>
          <div name="err_email" className={classNames(styles.errorMessageForInput)}></div>
        </div>

        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)} >  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faLock} />
            <input validatorname="password" onChange={checkValidate} ref={inputRef_password} type="password" placeholder="パスワード" className={classNames(styles.inputField, styles.password)} required pattern={pwPattern} />
          </div>
          <div name="err_password" className={classNames(styles.errorMessageForInput)}></div>
        </div>
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)} >  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faLock} />
            <input validatorname="confirmpassword" onChange={checkValidate} ref={inputRef_confirmpassword} type="password" placeholder="パスワード(確認用)" className={classNames(styles.inputField, styles.password)} required pattern={pwPattern} />
          </div>
          <div name="err_confirmpassword" className={classNames(styles.errorMessageForInput)}></div>
        </div>
      </form>
      <div className={classNames(styles.errorMessage)}>{forgetPasswordErrorState? "認証コード間違っております。": ""}</div>
      <div className={classNames(styles.submitButtonDiv)}>
        <button type="button" className={classNames(styles.loginButton)} onClick={forgetPassword} >パスワードを変更</button>
      </div>
    </div>
  )

  const passwordResetSuccess = (
    <div>
      <div className={classNames(styles.successDiv)}>      
        <p>パスワード変更しました。</p>
      </div>
      <div className={classNames(styles.signupButtonDiv)}>
        <button type="submit" className={classNames(styles.loginButton)} onClick={() => {forgetPasswordSubmitSuccessCallback()}}>確定</button>
      </div>
    </div>
  )

  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.logo)}><img src={logo} /></div>
      <div className={classNames(styles.login)}>
        <div className={classNames(styles.title)}>
          パスワードを変更  
        </div>
        {(forgetPasswordSuccessState)? passwordResetSuccess: inputForm}
        
      </div>
      <div className={classNames(styles.regButtonDiv)}>
        <button type="submit" className={classNames(styles.regButton)} onClick={signIn}>ログインへ</button>
      </div>
      <div className={classNames(styles.height20)}></div>
    </div>
  )
}
