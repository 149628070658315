import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope,*/ faUser, faLock, faArrowRight, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope/*, faUser, faLock, faArrowRight*/ } from '@fortawesome/free-regular-svg-icons'

import { Auth } from 'aws-amplify';
import styles from "./LiveXRVerifyPage.scss";
import logo from "../../assets/images/logo/logo.png";


LiveXRVerifyPage.propTypes = {
  verifySuccessCallback: PropTypes.func,
  defaultEmail: PropTypes.string,
  onClickLogin: PropTypes.func
};

LiveXRVerifyPage.defaultProps = {
  defaultEmail: ""
}

export function LiveXRVerifyPage({verifySuccessCallback, defaultEmail, onClickLogin}) {
  const [verifyErrorState, setVerifyErrorState] = useState(false);
  const [verifyErrorMsg, setVerifyErrorMessage] = useState("");
  const [verifySuccessState, setVerifySuccessState] = useState(false);
  const inputRef_userName = useRef(null);
  const inputRef_code = useRef(null);

  const verifyEmail = async () => {
    if (!checkAllValidate()) {
      return;
    }

    await Auth.confirmSignUp(inputRef_userName.current.value, inputRef_code.current.value).then((res) => {
      setVerifySuccessState(true);
      //verifySuccessCallback();
    }).catch((error) => {
      setVerifySuccessState(false);
      setVerifyErrorState(true);
      let errorMsg = "";
      switch (error.code) {
        case "UserNotFoundException":
          errorMsg = "メールアドレスが見つかりません";
          break;
        case "NotAuthorizedException":
          errorMsg = "メールアドレスは認証済みです";
          break;
        default:
          errorMsg = "認証コードが間違っております";
          break;
      }
      setVerifyErrorMessage(errorMsg);
    });
  }

  const resendCode = () => {
    if (inputRef_userName.current.value != null && inputRef_userName.current.value != "") {
      Auth.resendSignUp(inputRef_userName.current.value).then(()=> {
        setVerifyErrorState(true);
        setVerifyErrorMessage("認証コードを送信しました");
      }).catch((error) => {
        setVerifyErrorState(true);
        let errorMsg = "";
        switch (error.code) {
          case "UserNotFoundException":
            errorMsg = "メールアドレスが見つかりません";
            break;
          case "InvalidParameterException":
            errorMsg = "メールアドレスは認証済みです";
            break;
          case "LimitExceededException":
            errorMsg = "試行制限を超えました。しばらくしてから試してください。";
            break
          default:
            errorMsg = "認証コードが間違っております";
            break;
        }
        setVerifyErrorMessage(errorMsg);
      });
    } else {
      var e = document.querySelector("[validatorname=\"email\"]");
      var errElem = document.querySelector("[name=\"err_email\"]");
      errElem.innerHTML = errMessage["email"]["valueMissing"];
      e.setCustomValidity(errMessage["email"]["valueMissing"]);
      e.classList.add(styles.inputError);
    }
  }

  const signIn = () => {
    onClickLogin();
  }

  const checkValidate = (e) => {
    checkElemValidate(e.target);
  }

  const errMessage = {
    "email": {
      "typeMismatch": "メールアドレスの入力形式が正しくありません。",
      "valueMissing": "メールアドレスを入力してください。"
    },
    "code": {
      "valueMissing": "認証コードを入力してください。"
    },
  }

  const checkElemValidate = (e) => {
    var isValid = true;
    e.classList.remove(styles.inputError);  
    
    var errElem = document.querySelector("[name=\"err_"+e.getAttribute("validatorname")+"\"]");
    if (e.validity.typeMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["typeMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["typeMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);
    }
    else if (e.validity.valueMissing) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["valueMissing"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["valueMissing"]);
      isValid = false;
      e.classList.add(styles.inputError); 
    }
    else {
      errElem.innerHTML = "";
      e.setCustomValidity("");
    }
    
    return isValid;
  }

  const checkAllValidate = (e) => {
    var isValid = true;
    document.querySelectorAll("input").forEach((elem) => {
      isValid = checkElemValidate(elem) && isValid;
    });
    return isValid;
  }

  const inputForm = (
    <div className={classNames(styles.formDiv)}>
      <form className={classNames(styles.form)} noValidate>
        
        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faEnvelope} />
            <input validatorname="email" onChange={checkValidate} ref={inputRef_userName} type="email" placeholder="メールアドレス" className={classNames(styles.inputField, styles.name)} required defaultValue={defaultEmail} />
          </div>
          <div name="err_email" className={classNames(styles.errorMessageForInput)}></div>
        </div>

        <div className={classNames(styles.credentialContainer)}>
          <div className={classNames(styles.userDetailInputBox)}>
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faUserCheck} />
            <input validatorname="code" onChange={checkValidate} ref={inputRef_code} type="text" placeholder="認証コード" className={classNames(styles.inputField, styles.name)} required />
          </div>
          <div name="err_code" className={classNames(styles.errorMessageForInput)}></div>
        </div>
        
      </form>
      <div className={classNames(styles.errorMessage)}>{verifyErrorState? verifyErrorMsg: ""}</div>
      <div className={classNames(styles.submitButtonDiv)}>
        <button type="button" className={classNames(styles.loginButton)} onClick={verifyEmail} >認証</button>
      </div>
      <div className={classNames(styles.submitButtonDiv)}>
        <button type="button" className={classNames(styles.loginButton)} onClick={resendCode} >コード再送</button>
      </div>
    </div>
  )

  const successContent = (
    <div>
      <div className={classNames(styles.successDiv)}>      
        <p>ご登録ありがとうございます。<br  className={classNames(styles.br)}/>続いてログインして下さい。</p>
      </div>
      <div className={classNames(styles.signupButtonDiv)}>
        <button type="submit" className={classNames(styles.loginButton)} onClick={() => {verifySuccessCallback()}}>確定</button>
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.logo)}><img src={logo} /></div>
      <div className={classNames(styles.login)}>
        <div className={classNames(styles.title)}>
          メールアドレス認証  
        </div>
        {(verifySuccessState)? successContent: inputForm}
      </div>
      <div className={classNames(styles.regButtonDiv)}>
        <button type="submit" className={classNames(styles.regButton)} onClick={signIn}>ログインへ</button>
      </div>
      <div className={classNames(styles.height20)}></div>
    </div>
  )
}
