export function addAnimationComponents(modelEl) {
  if (!modelEl.components["animation-mixer"]) {
    return;
  }

  if (!modelEl.querySelector("[loop-animation]")) {
    modelEl.setAttribute("loop-animation", {"paused": true});
  } else {
    const loopAnims = document.querySelectorAll("[loop-animation]");
    loopAnims.forEach(loopAnim => {
      const isPaused = loopAnim.object3D.name.indexOf("Paused") != -1
      const isLoop = loopAnim.object3D.name.indexOf("PlayOnce") == -1
      loopAnim.setAttribute("loop-animation", {
        "paused": isPaused,
        "loop": isLoop
      })
    });
  }
}
