import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./SelfieCamera.scss";
import { SelfieCameraToolbarButton } from "./SelfieCameraComponent";
import merge from "deepmerge";
import configs from "../../utils/configs";
import { SOUND_SELFIE_CAMERA } from "../../systems/sound-effects-system";

const RENDER_WIDTH = 1280;
const RENDER_HEIGHT = 720;

const HIDDEN_SCALE = 0.00000001;

const snapCanvas = document.createElement("canvas");

async function pixelsToPNG(pixels, width, height) {
  snapCanvas.width = width;
  snapCanvas.height = height;
  const context = snapCanvas.getContext("2d");
  const imageData2 = context.createImageData(width, height);
  const imageData = context.createImageData(width, height);

  imageData2.data.set(pixels);
  var canvasRGBA = imageData2.data;
  var newRGBA = imageData.data;
  for (var i = 0; i < height; i++) {
    for (var j = 0; j < width; j++) {
      newRGBA[j * 4 + (height - 1 - i) * width * 4] = canvasRGBA[j * 4 + i * width * 4];
      newRGBA[1 + j * 4 + (height - 1 - i) * width * 4] = canvasRGBA[1 + j * 4 + i * width * 4];
      newRGBA[2 + j * 4 + (height - 1 - i) * width * 4] = canvasRGBA[2 + j * 4 + i * width * 4];
      newRGBA[3 + j * 4 + (height - 1 - i) * width * 4] = canvasRGBA[3 + j * 4 + i * width * 4];
    }
  }

  context.putImageData(imageData, 0, 0);

  context.scale(1, -1);
  const blob = await new Promise(resolve => snapCanvas.toBlob(resolve));
  return new File([blob], "snap.png", { type: "image/png" });
}

export class SelfieCameraTools extends Component {
  static propTypes = {
    onClose: PropTypes.func
  };
  constructor(props) {
    super(props);
  }

  state = {
    isClickShot: false,
    file: null,
    isInvert: true
  };
  componentDidMount() {
    this.sceneEl = document.querySelector("a-scene");

    this.el = document.getElementById("avatar-selfie-camera-node");
    this.renderTarget = new THREE.WebGLRenderTarget(RENDER_WIDTH, RENDER_HEIGHT, {
      format: THREE.RGBAFormat,
      minFilter: THREE.LinearFilter,
      magFilter: THREE.NearestFilter,
      encoding: THREE.GammaEncoding,
      depth: false,
      stencil: false
    });
    this.el.object3D.visible = true;
    const rig = document.getElementById("avatar-rig");
    const camera = document.getElementById("avatar-pov-node");
    this.avatarCameraObject3D = camera.object3D;
    this.rigObject3D = rig.object3D;
    this.cameraSystem = this.sceneEl.systems["camera-tools"];

    //自撮カメラを用意
    if (AFRAME.utils.device.isMobile()) {
      // 画面のサイズ変動があった時に高さを再計算する
      const setFillHeight = () => {
        //Safari Hack
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }

      window.addEventListener('resize', setFillHeight);

      setFillHeight();
      this.camera = new THREE.PerspectiveCamera(28.5, RENDER_WIDTH / RENDER_HEIGHT, 0.1, 30000);
    } else {
      this.camera = new THREE.PerspectiveCamera(50, RENDER_WIDTH / RENDER_HEIGHT, 0.1, 30000);
    }
    this.camera.rotation.y = Math.PI; //自撮だから180度回転
    this.camera.position.z = this.camera.position.z - 1;
    this.camera.position.y = this.camera.position.y - 0.2;
    this.camera.matrixNeedsUpdate = true;
    this.el.setObject3D("camera", this.camera);

    const material = new THREE.MeshBasicMaterial({
      map: this.renderTarget.texture
    });
    material.map.isVideoTexture = false;
    material.map.update = () => {};
    const width = 1;
    const geometry = new THREE.PlaneBufferGeometry(width, width / this.camera.aspect);
    const environmentMapComponent = this.sceneEl.components["environment-map"];
    if (environmentMapComponent) {
      environmentMapComponent.applyEnvironmentMap(this.el.object3D);
    }
    //プレビュースクリーンを準備
    this.screen = new THREE.Mesh(geometry, material);
    this.screen.matrixNeedsUpdate = true;
    this.el.object3D.matrixNeedsUpdate = true;
    this.el.setObject3D("screen", this.screen);

    //自撮カメラのtickを回す

    if (!window.LIVEXR || !window.LIVEXR.extraTick || !window.LIVEXR.extraTick.cameraRender) {
      window.LIVEXR = merge(window.LIVEXR? window.LIVEXR: {}, {extraTick: { cameraRender: null }});
    }
    window.LIVEXR.extraTick.cameraRender = () => {
      this.tick();
    };
  }

  async onShot() {
    const sfx = this.el.sceneEl.systems["hubs-systems"].soundEffectsSystem;
    sfx.playSoundOneShot(SOUND_SELFIE_CAMERA);
    const renderer = this.sceneEl.renderer;
    const snapPixels = new Uint8Array(RENDER_WIDTH * RENDER_HEIGHT * 4);
    renderer.readRenderTargetPixels(this.renderTarget, 0, 0, RENDER_WIDTH, RENDER_HEIGHT, snapPixels);
    const pixel = await pixelsToPNG(snapPixels, RENDER_WIDTH, RENDER_HEIGHT);
    this.setState({
      isClickShot: true,
      file: pixel
    });
  }

  tick() {
    this.rendering();
    const renderer = this.sceneEl.renderer;
    renderer.setRenderTarget(this.renderTarget);
    renderer.render(this.sceneEl.object3D, this.camera);
    renderer.setRenderTarget(null);

    //レンダーが終わったら、プレイヤーの頭を消す
    const playerHead = this.cameraSystem.playerHead;
    if (playerHead) {
      playerHead.visible = false;
      playerHead.scale.set(HIDDEN_SCALE, HIDDEN_SCALE, HIDDEN_SCALE);
      playerHead.updateMatrices(true, true);
      playerHead.updateMatrixWorld(true, true);
    }
  }

  rendering() {
    //プレイヤーの頭を出す
    const playerHead = this.cameraSystem.playerHead;
    if (playerHead) {
      let scale = 1;
      playerHead.visible = true;
      playerHead.scale.set(scale, scale, scale);
      playerHead.updateMatrices(true, true);
      playerHead.updateMatrixWorld(true, true);
    }

    this.screen.matrixNeedsUpdate = true;
    this.sceneEl.camera.updateMatrices();

    const targetWorldPos = new THREE.Vector3();
    const angledCameraForward = new THREE.Vector3();
    const defaultRight = new THREE.Vector3(1, 0, 0);

    const obj = this.screen.el.object3D;
    this.avatarCameraObject3D.updateMatrices();

    angledCameraForward.set(0, 0, -1);

    if (AFRAME.utils.device.isMobile()) {
      angledCameraForward.multiplyScalar(1.5);
    } else {
      angledCameraForward.multiplyScalar(0.67);
    }

    //カメラの位置情報をWORLDに
    this.avatarCameraObject3D.localToWorld(angledCameraForward);
    obj.parent.worldToLocal(angledCameraForward);
    obj.position.copy(angledCameraForward);

    obj.updateMatrices(true);
    this.avatarCameraObject3D.getWorldPosition(targetWorldPos);
    obj.lookAt(targetWorldPos);
    obj.matrixNeedsUpdate = true;
  }

  componentWillUnmount() {
    delete window.LIVEXR.extraTick.cameraRender;
    this.el.object3D.visible = false;
  }

  onCloseButton() {
    this.props.onClose();
  }
  onCameraInvert() {
    this.camera.matrixNeedsUpdate = true;
    this.camera.rotation.y = this.camera.rotation.y + Math.PI;
  }

  onDownLoad() {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(this.state.file);
    link.download = configs.app.photoPrefix + "_" + getNowYMD() + ".png";
    link.click();
    this.setState({
      isClickShot: false,
      file: null
    });
    function getNowYMD() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var result = y + "-" + m + "-" + d + "_" + date.getHours() + date.getMinutes() + date.getSeconds();
      return result;
    }
  }

  render() {
    return (
      <div className={styles.selfieCamera}>
        <div className={styles.selfieCameraInner + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}>
          {!this.state.isClickShot && (
            <div
              className={styles.selfieCameraInvert + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}
              onClick={e => {
                this.onCameraInvert();
              }}
            >
              <div>
                <span>反転</span>
              </div>
            </div>
          )}
          <div className={styles.selfieCameraContents}>
            <img
              src={this.state.file && URL.createObjectURL(this.state.file)}
              style={{
                opacity: this.state.file ? 1 : 0
              }}
            />
          </div>
          {!this.state.isClickShot && (
            <div
              className={styles.selfieCameraCapture + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}
              onClick={e => {
                this.onShot();
              }}
            >
              <div>
                <p>撮影</p>
              </div>
            </div>
          )}

          {this.state.isClickShot && (
            <div className={styles.selfieCameraRecapture + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}>
              <div
                onClick={e => {
                  this.setState({
                    isClickShot: false,
                    file: null
                  });
                }}
              >
                <p>撮影しなおす</p>
              </div>
            </div>
          )}
          {this.state.isClickShot && (true || !AFRAME.utils.device.isMobile()) && (
            <div
              className={styles.selfieCameraDownload + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}
              onClick={e => {
                this.onDownLoad();
              }}
            >
              <div>
                <p>保存</p>
              </div>
            </div>
          )}
          {this.state.isClickShot && (false && AFRAME.utils.device.isMobile()) && (
            <div className={styles.selfieCameraHold + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}>
              <div>
                <p>画像を長押しでダウンロードできます</p>
              </div>
            </div>
          )}
          {this.state.isClickShot && (
            <div
              className={styles.selfieCameraClose + " " + (AFRAME.utils.device.isMobile() ? "-mobile" : "")}
              onClick={e => {
                this.onCloseButton();
              }}
            >
              <div></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export function SelfieCameraToolbarButtonContainer(props) {
  return <SelfieCameraToolbarButton {...props} />
}
