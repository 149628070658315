import React, { forwardRef } from "react";
import { ReactComponent as CameraIcon } from "../icons/Camera.svg";
import { ReactComponent as RaiseHandIcon } from "../icons/Icon_Button_Hand_Raise.svg";
import { ReactComponent as PutDownHandIcon } from "../icons/Icon_Button_Hand_Close.svg";
import { ToolbarButton } from "../input/ToolbarButton";
import styles from "./RaiseHand.scss";
import { FormattedMessage, useIntl, defineMessages, FormattedRelativeTime } from "react-intl";

export function RaiseHandToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={props.isOn? <RaiseHandIcon />: <PutDownHandIcon />}
      preset="purple"
      label={<FormattedMessage id="raise-hand-toolbar-button" defaultMessage="Raise Hand" />}
      statusColor={props.isOn? "green":　"red"}
    />
  );
}
