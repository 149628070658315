import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ThirdPersonOnIcon } from "../icons/ThirdPersonOn.svg";
import { ReactComponent as ThirdPersonOffIcon } from "../icons/ThirdPersonOff.svg";
import { ToolbarButton } from "../input/ToolbarButton";
import { FormattedMessage } from "react-intl";

export function ThirdPersonViewContainer({ scene, isThirdPerson, toggleMute }) {
  const buttonRef = useRef();

  return (
    <ToolbarButton
      ref={buttonRef}
      icon={isThirdPerson ? <ThirdPersonOnIcon /> : <ThirdPersonOffIcon />}
      label={<FormattedMessage id="third-person-button-container.label" defaultMessage="Third Person" />}
      preset="basic"
      onClick={toggleMute}
    />
  );
}

ThirdPersonViewContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  isThirdPerson: PropTypes.bool,
  toggleThirdPerson: PropTypes.func
};
