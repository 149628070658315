import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope,*/ faUser, faLock, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope/*, faUser, faLock, faArrowRight*/ } from '@fortawesome/free-regular-svg-icons'

import { Auth } from 'aws-amplify';
import styles from "./LiveXRForgotPasswordPage.scss";
import logo from "../../assets/images/logo/logo.png";


LiveXRForgotPasswordPage.propTypes = {
  forgetPasswordSuccessCallback: PropTypes.func,
  onClickLogin: PropTypes.func
};

export function LiveXRForgotPasswordPage({forgetPasswordSuccessCallback, onClickLogin}) {
  const [forgetPasswordErrorState, setForgotPasswordErrorState] = useState(false);
  const [forgetPasswordSentState, setForgotPasswordSentState] = useState(false);
  const inputRef_userName = useRef(null);

  const forgetPassword = async () => {
    if (!checkAllValidate()) {
      return;
    }
    

    // Send confirmation code to user's email
    await Auth.forgotPassword(inputRef_userName.current.value).then((res) => {
      // TODO: show success msg, that click next to forgetPasswordSubmit
      setForgotPasswordSentState(true);
      //forgetPasswordSuccessCallback();
    }).catch((error) => {
      setForgotPasswordSentState(false);
    setForgotPasswordErrorState(true);
    });
  }

  const signIn = () => {
    onClickLogin();
  }

  const checkValidate = (e) => {
    checkElemValidate(e.target);
  }

  const errMessage = {
    "email": {
      "typeMismatch": "メールアドレスの入力形式が正しくありません。",
      "valueMissing": "登録したメールアドレスを入力してください。"
    }
  }

  const checkElemValidate = (e) => {
    var isValid = true;
    e.classList.remove(styles.inputError);  
    
    var errElem = document.querySelector("[name=\"err_"+e.getAttribute("validatorname")+"\"]");
    if (e.validity.typeMismatch) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["typeMismatch"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["typeMismatch"]);
      isValid = false;
      e.classList.add(styles.inputError);
    }
    else if (e.validity.valueMissing) {
      errElem.innerHTML = errMessage[e.getAttribute("validatorname")]["valueMissing"];
      e.setCustomValidity(errMessage[e.getAttribute("validatorname")]["valueMissing"]);
      isValid = false;
      e.classList.add(styles.inputError); 
    }
    else {
      errElem.innerHTML = "";
      e.setCustomValidity("");
    }
    
    return isValid;
  }

  const checkAllValidate = (e) => {
    var isValid = true;
    document.querySelectorAll("input").forEach((elem) => {
      isValid = checkElemValidate(elem) && isValid;
    });
    return isValid;
  }

  const inputForm = (
    <div className={classNames(styles.formDiv)}>
      <form className={classNames(styles.form)} noValidate>
        <div className={classNames(styles.credentialContainer)}>
          
          <div className={classNames(styles.userDetailInputBox)}>  
            <FontAwesomeIcon className={classNames(styles.userDetailInputBoxIcon)} icon={faEnvelope} />
            <input validatorname="email" onChange={checkValidate} ref={inputRef_userName} type="email" placeholder="メールアドレス" id="email" className={classNames(styles.inputField, styles.name)} required />
          </div>
          <div name="err_email" className={classNames(styles.errorMessageForInput)}></div>
        </div>
      </form>
      <div className={classNames(styles.errorMessage)}>{forgetPasswordErrorState? "メールアドレス見つかりません。": ""}</div>
      <div className={classNames(styles.submitButtonDiv)}>
        <button type="button" className={classNames(styles.loginButton)} onClick={forgetPassword} >認証メールを送信</button>
      </div>
    </div>
  )

  const successContent = (
    <div>
      <div className={classNames(styles.successDiv)}>      
        <p>メールアドレスへ認証コードを送信しました。</p>
      </div>
      <div className={classNames(styles.submitButtonDiv)}>
        <button type="button" className={classNames(styles.loginButton)} onClick={() => {forgetPasswordSuccessCallback()}}>確定</button>
      </div>
    </div>
  )

  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.logo)}><img src={logo} /></div>
      <div className={classNames(styles.login)}>
        <div className={classNames(styles.title)}>
          メールアドレス認証  
        </div>
        {(forgetPasswordSentState)? successContent: inputForm}
      </div>
      {(forgetPasswordSentState)? <></>:
        <div className={classNames(styles.regButtonDiv)}>
          <button type="submit" className={classNames(styles.regButton)} onClick={signIn}>ログインへ</button>
        </div>
      }
      <div className={classNames(styles.height20)}></div>
    </div>
  )
}
