import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import configs from "../../utils/configs";
import { useIntl } from "react-intl";
import { LoadingEvent } from "./LoadingEvent";

export function LoadingEventContainer({ scene }) {
  const intl = useIntl();

  return <LoadingEvent />;
}

LoadingEventContainer.propTypes = {
  
};
