import React, { forwardRef } from "react";
import { ReactComponent as CameraIcon } from "../icons/Event.svg";
import { ToolbarButton } from "../input/ToolbarButton";
import { FormattedMessage, useIntl, defineMessages, FormattedRelativeTime } from "react-intl";

export function EventDialogToolbarButton(props) {
  return (
    <ToolbarButton
      {...props}
      icon={<CameraIcon />}
      preset="accent5"
      label={<FormattedMessage id="event-dialog-toolbar-button" defaultMessage="Event" />}
    />
  );
}
