import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import eventDataJson from "../../assets/json/loading_screen_list.json"
import { Carousel } from 'react-responsive-carousel';
import styles from "./LoadingEvent.scss";
import configs from "../../utils/configs";

export class LoadingEvent extends Component {
  state = {
    eventData: {}
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      eventData: eventDataJson.eventData.filter((data) => {
        const dataStartDate = new Date(data.startDate);
        const dataEndDate = new Date(data.endDate);
        const currentDate = new Date();
        if (data.image && (currentDate >= dataStartDate && currentDate <= dataEndDate)) {
          return true;
        } else {
          return false;
        }
      })
    })
  }

  render() {
    if (this.state.eventData && this.state.eventData.length > 0) {
      return (
        <div className={styles.carouselContainer}>
          {
            <Carousel
              infiniteLoop={true}
              autoPlay={true}
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              interval={4000}
              dynamicHeight={true}
            >
              {this.state.eventData.map((record) => {
                return record.url == ""? (
                  <div>
                    <img src={"https://" + configs.CORS_PROXY_SERVER + "/" + record.image} />
                    {
                      record.content? <p className="legend">{record.content}</p>: null
                    }
                  </div>
                ):(
                  <a href={record.url} target="_blank">
                  <div>
                      <img src={"https://" + configs.CORS_PROXY_SERVER + "/" + record.image} />
                      {
                        record.content? <p className="legend">{record.content}</p>: null
                      }
                  </div>
                  </a>
                )
              })}
              
            </Carousel>
          }
        </div>
        // <Marquee
        //   className={isMobile? styles.MarqueeContatinerSP: styles.MarqueeContatiner}
        //   gradient={false}
        //   speed={50}
        //   pauseOnHover={true}
        //   play={true}
        //   delay={1}
        // >
        //   {this.state.eventData.map((record) => {
        //     return record.url == ""? (
        //       <div className={styles.MarqueeContent}>{record.content}</div>
        //     ):(
        //       <div className={styles.MarqueeContent}><a href={record.url} target="_blank">{record.content}</a></div>
        //     )
        //   })}
        // </Marquee>
      );
    }
    return (
      <></>
    );
  }
}

export function LoadingEvent2() {

  return (
    <>
      <div style={{
        width: "100%"
      }}>
        Test
      </div>
    </>
    // <LoadingScreenLayout
    //   logoSrc={logoSrc}
    //   center={
    //     <>
    //       <Spinner />
    //       <p>{message}</p>
    //     </>
    //   }
    //   bottom={
    //     <>
    //       <h3>{infoMessage.heading}</h3>
    //       <p>{infoMessage.message}</p>
    //     </>
    //   }
    // />
  );
}

LoadingEvent.propTypes = {
  // logoSrc: PropTypes.string,
  // message: PropTypes.node,
  // infoMessages: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     heading: PropTypes.node.isRequired,
  //     message: PropTypes.node.isRequired
  //   })
  // )
};

LoadingEvent.defaultProps = {
  // infoMessages: []
};
